import React from 'react';
import { Navbar } from './components/Navbar';
import Footer from './components/Footer';
import { BrowserRouter as Router, Switch, Route } from 'react-router-dom';
import PageContent from './components/PageContent';
import FloatingButton from './components/FloatingButton';
import Search from './components/Search';
import RegisterPage from './pages/RegisterPage';
import SigninPage from './pages/SigninPage';
import EventPage from './pages/EventPage';
import NewsPage from './pages/NewsPage';
import { ContextProvider } from './context/UserContext';
import UserProfilePage from './pages/UserProfilePage';
import ResetPasswordPage from './pages/ResetPasswordPage';
import VerficationPage from './pages/VerficationPage';
import PrivateRoutes from './routes/PrivateRoutes';
import './App.css';

function App() {
  return (
    <Router>
      <div className='App bg-grad'>
        <ContextProvider>
          <Switch>
            <Route exact path='/register' component={RegisterPage} />
            <Route exact path='/signin' component={SigninPage} />
            <Route path='/password-reset' component={ResetPasswordPage} exact />
            <React.Fragment>
              <Navbar />
              <main className=''>
                <FloatingButton />
                <Route path='/' component={PageContent} exact />
                <Route path='/verification' component={VerficationPage} exact />
                <Route
                  path='/profile'
                  render={() => (
                    <PrivateRoutes>
                      <UserProfilePage />
                    </PrivateRoutes>
                  )}
                  exact
                />
                <Route
                  path='/pages/route/:name'
                  component={PageContent}
                  exact
                />
                <Route
                  path='/search'
                  exact
                  render={() => <Search classes='input-group-lg px-10' />}
                />
                <Route path='/events/:id' component={EventPage} exact />
                <Route path='/news/:id' component={NewsPage} exact />
              </main>
              <Footer />
            </React.Fragment>
          </Switch>
        </ContextProvider>
      </div>
    </Router>
  );
}
export default App;
