import { createContext, useReducer } from "react";
import {
  userInitialState,
  UserLoginReducer,
  UserRegisterReducer,
  UserUpdatePasswordReducer,
  UserUpdateReducer,
  UserVerifyEmailReducer,
  UserResetPasswordReducer,
  UserForgotPasswordReducer,
  EmailEcoSystemReducer,
  EmailLeasingReducer
} from "../reducers/userReducers";

const initialState = {
  ...userInitialState,
};
// initial user context
export const UserContext = createContext(null);

// const to use reducer dispatch function to call actions
export const UserDispatchContext = createContext();

// function to combine reducers
const combineReducers =
  (...reducers) =>
  (state = initialState, action) => {
    for (let i = 0; i < reducers.length; i++)
      state = reducers[i](state, action);
    return state;
  };

// Context
export const ContextProvider = ({ children }) => {
  const [state, dispatch] = useReducer(
    combineReducers(
      UserLoginReducer,
      UserRegisterReducer,
      UserUpdateReducer,
      UserUpdatePasswordReducer,
      UserVerifyEmailReducer,
      UserResetPasswordReducer,
      UserForgotPasswordReducer,
      EmailEcoSystemReducer,
      EmailLeasingReducer
    ),
    initialState
  ); // User reducer function

  return (
    <UserContext.Provider value={state}>
      <UserDispatchContext.Provider value={dispatch}>
        {children}
      </UserDispatchContext.Provider>
    </UserContext.Provider>
  );
};
