import React, { useState } from "react";
import SwipeableViews from "react-swipeable-views";
import { autoPlay } from "react-swipeable-views-utils";
import Pagination from "./Pagination";
import AvatarCardComment from "./AvatarCardComment";

const AutoPlaySwipeableViews = autoPlay(SwipeableViews);

const styles = {
  root: {
    position: "relative",
  },
  slide: {
    padding: 15,
    minHeight: 300,
    color: "#fff",
  },
};

const groupItems = (data, numberPerGroup) =>
  data.reduce((accumulated, currentItem, currentIndex) => {
    const cuurentGroupIndex = Math.floor(currentIndex / numberPerGroup);
    accumulated[cuurentGroupIndex] = [
      ...(accumulated[cuurentGroupIndex] || []),
      currentItem,
    ];
    return accumulated;
  }, []);

const SwipeableView = ({ content }) => {
  const [index, setIndex] = useState(0);

  const handleChangeIndex = (index) => {
    setIndex(index);
  };
  return (
    <div style={styles.root} className="px-md-10 mx-4">
      <div className="text-center display-4 fw-bolder text-info">
        {content.header ? content.header : "Our Customer Says"}
      </div>
      <AutoPlaySwipeableViews
        index={index}
        autoplay={true}
        interval={5000}
        enableMouseEvents
        onChangeIndex={handleChangeIndex}
      >
        {groupItems(content.testimonial, 3).map((group, index) => (
          <div style={Object.assign({}, styles.slide)} key={index}>
            <div className="row">
              {group.map((item) => (
                <div className="col-lg-4 col-12" key={item.id}>
                  <AvatarCardComment
                    avatarLink={
                      item.avtar?.url
                        ? `${process.env.REACT_APP_ENDPOINT}${item.avtar?.url}`
                        : ""
                    }
                    title={item.title}
                    link={item.link}
                    logoLink={
                      item.logo?.url
                        ? `${process.env.REACT_APP_ENDPOINT}${item.logo?.url}`
                        : ""
                    }
                    comment={item.comment}
                    altText={
                      item.avtar?.alternativeText
                        ? item.avtar.alternativeText
                        : ""
                    }
                  />
                </div>
              ))}
            </div>
          </div>
        ))}
      </AutoPlaySwipeableViews>
      <Pagination dots={3} index={index} onChangeIndex={handleChangeIndex} />
    </div>
  );
};

export default SwipeableView;
