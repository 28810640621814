import React, { useState, useEffect, useContext } from "react";
import "./RegisterPage.scss";
import { Formik } from "formik";
import * as yup from "yup";
import { updatePassword, updateUser, logout } from "../actions/userActions";
import { UserContext, UserDispatchContext } from "../context/UserContext";
import { Toast } from "bootstrap";
import { useHistory } from "react-router-dom";

const UserProfilePage = () => {
  const userSchema = yup.object().shape({
    firstName: yup
      .string()
      .min(2, "Must be more than two characters long")
      .required("Must be filled out"),
    lastName: yup
      .string()
      .min(2, "Must be more than two characters long")
      .required("Must be filled out"),
  });

  const passwordSchema = yup.object().shape({
    currentPassword: yup
      .string()
      .min(6, "Must be more than six characters long"),
    newPassword: yup.string().min(6, "Must be more than six characters long"),
  });

  const history = useHistory();

  const [updateLoading, setUpdateLoading] = useState();
  const [passwordLoading, setPasswordLoading] = useState();

  const [updateErroropen, setUpdateErrorOpen] = useState();
  const [passwordErroropen, setPasswordErrorOpen] = useState();

  const dispatch = useContext(UserDispatchContext);

  const { loading, error, userInfo } = useContext(UserContext);

  const handleUserUpdate = async (values) => {
    const { firstName, lastName } = values;
    let payload = {};
    payload.firstName = firstName;
    payload.lastName = lastName;
    try {
      setUpdateErrorOpen(false);
      setPasswordErrorOpen(false);
      setPasswordLoading(false);
      setUpdateLoading(false);

      setUpdateLoading(true);
      const data = await updateUser(dispatch, payload);
      if (data) {
        history.push("/");
      } else setUpdateErrorOpen(true);
    } catch (err) {
      console.log(err);
    }
  };

  const handlePasswordUpdate = async (passwordValue) => {
    const { newPassword, currentPassword } = passwordValue;
    let payload = {};
    payload.currentPassword = currentPassword;
    payload.newPassword = newPassword;
    try {
      setUpdateErrorOpen(false);
      setPasswordErrorOpen(false);
      setPasswordLoading(false);
      setUpdateLoading(false);

      setPasswordLoading(true);
      const data = await updatePassword(dispatch, payload);

      if (data) {
        await logout(dispatch);
        history.push("/");
      } else setPasswordErrorOpen(true);
    } catch (err) {
      console.log(err);
    }
  };

  useEffect(() => {
    let updateToast = new Toast(document.getElementById("errorToastUpdate"));
    let passwordToast = new Toast(
      document.getElementById("errorToastPassword")
    );
    if (passwordErroropen) {
      passwordToast.show();
    } else {
      passwordToast.hide();
    }
    if (updateErroropen) {
      updateToast.show();
    } else {
      updateToast.hide();
    }
  }, [updateErroropen, passwordErroropen]);

  return (
    <>
      <div className="container">
        <div className="row border border-top-0 border-bottom-0 border-grey d-flex mx-md-10 align-items-start">
          <div className="col-lg-12 pt-2 pb-4 px-7 mb-5 border-left rightBlock bg-white">
            <div className="row mt-3">
              <div className="col">
                <p className="fs-5 fw-bolder mb-0">Personal Info</p>
                <p className="fs-6" style={{ color: "#979A98" }}>
                  {userInfo?.email || "Update personal info"}
                </p>
              </div>
            </div>
            <Formik
              validationSchema={userSchema}
              onSubmit={handleUserUpdate}
              initialValues={{
                firstName: userInfo?.first_name || "",
                lastName: userInfo?.last_name || "",
              }}
            >
              {({
                handleSubmit,
                handleChange,
                handleBlur,
                values,
                touched,
                errors,
              }) => (
                <form onSubmit={handleSubmit}>
                  <div className="form-group mb-4 row">
                    <label
                      htmlFor="firstName"
                      className="text-dark fw-bold col-sm-2 col-form-label"
                    >
                      First Name
                    </label>
                    <div className="col-sm-10">
                      <input
                        type="text"
                        className="form-control w-100 bg-transparent form-input"
                        id="firstName"
                        value={values.firstName}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        placeholder="Enter First Name"
                      />
                      {touched.firstName && errors.firstName ? (
                        <p className="text-primary fs-7">{errors.firstName}</p>
                      ) : null}
                    </div>
                  </div>
                  <div className="form-group mb-4 row">
                    <label
                      htmlFor="lastName"
                      className="text-dark fw-bold col-sm-2 col-form-label"
                    >
                      Last Name
                    </label>
                    <div className="col-sm-10">
                      <input
                        type="text"
                        className="form-control w-100 bg-transparent form-input "
                        id="lastName"
                        value={values.lastName}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        placeholder="Enter Last Name"
                      />
                      {touched.lastName && errors.lastName ? (
                        <p className="text-primary fs-7">{errors.lastName}</p>
                      ) : null}
                    </div>
                  </div>

                  <button
                    type="submit"
                    className="bg-info border-0 mt-2 text-white w-100 py-2"
                  >
                    {updateLoading && loading ? (
                      <div
                        className="spinner-border spinner-border-sm"
                        role="status"
                      >
                        <span className="visually-hidden">Loading...</span>
                      </div>
                    ) : (
                      "Save"
                    )}
                  </button>
                </form>
              )}
            </Formik>
            <div
              id="errorToastUpdate"
              className="toast"
              role="alert"
              aria-live="assertive"
              aria-atomic="true"
            >
              <div className="toast-header">
                <strong className="me-auto text-warning">{error}</strong>
                <button
                  type="button"
                  className="btn-close text-end"
                  data-bs-dismiss="toast"
                  aria-label="Close"
                ></button>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="container">
        <div className="row border border-top-0 border-bottom-0 border-grey d-flex mx-md-10 align-items-start">
          <div className="col-lg-12 pt-2 pb-4 px-7 border-left rightBlock h-100 bg-white">
            <div className="row mt-3">
              <div className="col">
                <p className="fs-5 fw-bolder mb-0">Password</p>
                <p className="fs-6" style={{ color: "#979A98" }}>
                  Update password
                </p>
              </div>
            </div>
            <Formik
              validationSchema={passwordSchema}
              onSubmit={handlePasswordUpdate}
              initialValues={{
                currentPassword: "",
                newPassword: "",
              }}
            >
              {({
                handleSubmit,
                handleChange,
                handleBlur,
                values,
                touched,
                errors,
              }) => (
                <form onSubmit={handleSubmit}>
                  <div className="form-group mb-4">
                    <input
                      type="password"
                      name="currentPassword"
                      className="form-control w-100 bg-transparent form-input"
                      id="currentPassword"
                      value={values.currentPassword}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      placeholder="Enter Old Password"
                    />
                    {touched.currentPassword && errors.currentPassword ? (
                      <p className="text-primary fs-7">
                        {errors.currentPassword}
                      </p>
                    ) : null}
                  </div>

                  <div className="form-group mb-4">
                    <input
                      type="password"
                      name="newPassword"
                      className="form-control w-100 bg-transparent form-input"
                      id="newPassword"
                      value={values.newPassword}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      placeholder="Enter New Password"
                    />
                    {touched.newPassword && errors.newPassword ? (
                      <p className="text-primary fs-7">{errors.newPassword}</p>
                    ) : null}
                  </div>
                  <button
                    type="submit"
                    className="bg-info border-0 mt-2 text-white w-100 py-2"
                  >
                    {passwordLoading && loading ? (
                      <div
                        className="spinner-border spinner-border-sm"
                        role="status"
                      >
                        <span className="visually-hidden">Loading...</span>
                      </div>
                    ) : (
                      "Update"
                    )}
                  </button>
                </form>
              )}
            </Formik>
            <div
              id="errorToastPassword"
              className="toast"
              role="alert"
              aria-live="assertive"
              aria-atomic="true"
            >
              <div className="toast-header">
                <strong className="me-auto text-warning">{error}</strong>
                <button
                  type="button"
                  className="btn-close text-end"
                  data-bs-dismiss="toast"
                  aria-label="Close"
                ></button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default UserProfilePage;
