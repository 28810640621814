import React, { useEffect, useContext } from "react";
import { verifyEmail } from "../actions/userActions";
import { useLocation } from "react-router-dom";
import { UserContext, UserDispatchContext } from "../context/UserContext";

const VerficationPage = ({ history }) => {
  const dispatch = useContext(UserDispatchContext);
  const { loading, error, userInfo } = useContext(UserContext);
  const { search } = useLocation();
  const params = new URLSearchParams(search);
  const token = params.get("token");
  useEffect(() => {
    async function verify() {
      let verifyPayload = {};
      verifyPayload.token = token;
      const data = await verifyEmail(dispatch, verifyPayload);
      if (data || userInfo) {
        history.push("/");
      }
    }
    verify();
  }, [dispatch, userInfo, history, token]);
  return (
    <>
      {loading && (
        <div className="spinner-border spinner-border-sm" role="status">
          <span className="visually-hidden">Loading...</span>
        </div>
      )}
      <div class="alert alert-danger" role="alert">
        {error}
      </div>
    </>
  );
};

export default VerficationPage;
