import React from 'react'
import { FaRegEnvelope } from "react-icons/fa";

function FloatingButton() {
    return (
        <div className="position-fixed" style={{right: '3rem', bottom: '9rem', zIndex: '999'}}>
            <a href='mailto:marketing@mcmasterinnovationpark.ca' className="btn btn-primary p-3 rounded-circle" style={{boxShadow: 'rgba(50, 50, 93, 0.25) 0px 2px 5px -1px, rgba(0, 0, 0, 0.3) 0px 1px 3px -1px'}}>
            <FaRegEnvelope size="2.5em" className="text-white" />
            </a>
        </div>
    )
}

export default FloatingButton;
