import React, { useState } from "react";
import ReactMarkdown from "react-markdown";
import remarkBreaks from "remark-breaks";
import FormModal from "./FormModal";

const TextDividerWButton = ({ content }) => {
  const [openForm, setOpenForm] = useState(false);

  function ButtonForm({ btn }) {
    if (btn.openForm) {
      return (
        <React.Fragment>
          <button
            onClick={() => setOpenForm(true)}
            style={{
              boxShadow:
                "rgba(50, 50, 93, 0.25) 0px 2px 5px -1px, rgba(0, 0, 0, 0.3) 0px 1px 3px -1px",
            }}
            className={`btn bg-${
              btn.color ? btn.color : "primary"
            }   align-self-center fw-bolder py-2 px-3 rounded-3 fs-6 text-white`}
          >
            {btn.name.toUpperCase()}
          </button>
          <FormModal
            show={openForm}
            backdrop={true}
            onHide={() => setOpenForm(false)}
            type={btn.formType}
          />
        </React.Fragment>
      );
    } else if (btn.toggleCollapse) {
      return (
        <button
          className={`btn bg-${
            btn.color ? btn.color : "primary"
          }   align-self-center fw-bolder py-2 px-3 rounded-3 fs-6 text-white`}
          data-bs-toggle="collapse"
          style={{
            boxShadow:
              "rgba(50, 50, 93, 0.25) 0px 2px 5px -1px, rgba(0, 0, 0, 0.3) 0px 1px 3px -1px",
          }}
          data-bs-target={`#${btn.collapseTarget}`}
          aria-expanded="false"
          aria-controls={btn.collapseTarget}
        >
          {btn.name.toUpperCase()}
        </button>
      );
    } else {
      return (
        <a
          href={btn.Link}
          target="_blank"
          rel="noreferrer noopener"
          type="button"
          style={{
            boxShadow:
              "rgba(50, 50, 93, 0.25) 0px 2px 5px -1px, rgba(0, 0, 0, 0.3) 0px 1px 3px -1px",
          }}
          className={`btn bg-${
            btn.color ? btn.color : "primary"
          }  align-self-center fw-bolder py-2 px-3 rounded-3 fs-4 text-white`}
        >
          {btn.name.toUpperCase()}
        </a>
      );
    }
  }

  return (
    <div className={content.targetName ? 'my-5 px-md-10 collapse' : 'my-5 px-md-10 d-flex flex-column'} id={content.targetName}>
      {content.introduction && (
        <p className="text-center fs-1 text-primary fw-bolder px-4 fw-bolder">
          {content.introduction}
        </p>
      )}
      {content.details && (
        <ReactMarkdown
          className={`text-dark ${
            content.alignToLeft ? "text-left" : "text-center"
          } fs-3 px-4 fw-bold mb-3`}
          remarkPlugins={[remarkBreaks]}
          linkTarget="_blank"
        >
          {content.details}
        </ReactMarkdown>
      )}
      {content.button && (
        <ButtonForm btn={content.button} />
      )}
    </div>
  );
};

export default TextDividerWButton;
