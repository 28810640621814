import React from "react";
import { Link } from "react-router-dom";
import { FaLocationArrow, FaCalendarCheck } from "react-icons/fa";

function EventCard({ content }) {
  return (
    <div className="py-5 gx-4">
      <div
        className="card rounded w-100 border-0"
        style={{
          boxShadow:
            "0 2px 2px 0 rgba(0, 0, 0, 0.14), 0 3px 1px -2px rgba(0, 0, 0, 0.2), 0 1px 5px 0 rgba(0, 0, 0, 0.12)",
        }}
      >
        {content.eventLogo && (
          <div
            className="mx-3"
            style={{
              marginTop: "-30px",
              overflow: "hidden",
              boxShadow:
                "0 16px 38px -12px rgba(0, 0, 0, 0.56), 0 4px 25px 0px rgba(0, 0, 0, 0.12), 0 8px 10px -5px rgba(0, 0, 0, 0.2)",
            }}
          >
            <img
              className="card-img-top rounded"
              alt={content.eventLogo.caption}
              src={`${process.env.REACT_APP_ENDPOINT}${content.eventLogo.url}`}
              style={{
                objectFit: "cover",
                objectPosition: "50%% 50%",
                // marginTop: "-30px",
                height: "15vw",
              }}
            />
          </div>
        )}

        <div className="card-body px-4 pt-3 pb-3">
          <div
            className="card-text"
            style={{ fontFamily: "Brandon Grotesque", fontWeight: 400 }}
          >
            {content.eventCategory && (
              <h6 className="text-info fs-6 fw-bolder">
                {content.eventCategory}
              </h6>
            )}
            {content.eventName && (
              <p className="text-dark fs-5 fw-bold text-truncate">{content.eventName}</p>
            )}
            {content.eventDescription && (
              <p className="text-truncate fs-7">{content.eventDescription}</p>
            )}
            <div className="d-flex flex-row justify-content-between">
              {content.location && (
                <div className="d-flex align-items-center">
                  <FaLocationArrow className="text-dark me-2" />
                  <div className="text-dark fs-7 fw-bolder">
                    {content.location}
                  </div>
                </div>
              )}
              <div className="d-flex align-items-center">
                <FaCalendarCheck className="text-dark me-2" />
                <div className="text-dark fs-7 fw-bolder">
                  {content.month} {content.day}, {content.year}
                </div>
              </div>
            </div>
          </div>
        </div>
        {content.id && (
          <Link
            className="stretched-link"
            to={`/events/${content.id}`}
            target="_blank"
            role="button"
          >
            <i className="fa fa-phone" aria-hidden="true"></i>
          </Link>
        )}
      </div>
    </div>
  );
}

export default EventCard;
