import React, { useRef } from "react";
import Picker from "react-month-picker";
import { FaCalendarAlt } from "react-icons/fa";
import "react-month-picker/css/month-picker.css";

const MonthPicker = ({ range, monthYear, setMonthYear, months }) => {
  const monthPickerRef = useRef();

  const showMonthPicker = (event) => {
    if (monthPickerRef && monthPickerRef.current) {
      monthPickerRef.current.show();
      event.preventDefault();
    }
  };

  const handleOnChange = (year, month) => {
    setMonthYear({ year: year, month: months[month - 1] });
  };

  return (
    <div className="">
      <div className="input-group my-5" style={{boxShadow: 'rgba(0, 0, 0, 0.45) 0px 25px 20px -20px'}}>
        <span className="input-group-text border-0 bg-transparent" id="basic-addon1">
          <FaCalendarAlt size="2.5em" className="text-secondary" />
        </span>
        <input
          type="text"
          className="form-control border-0 border-bottom bg-transparent"
          placeholder="Pick a month"
          style={{boxShadow: 'none'}}
          value={`${monthYear.month}, ${monthYear.year}`}
          onClick={showMonthPicker}
          aria-label="Username"
          readOnly
          aria-describedby="basic-addon1"
        />
      </div>

      <Picker
        ref={monthPickerRef}
        lang={months}
        years={range}
        value={monthYear}
        onChange={handleOnChange}
      />
    </div>
  );
};

export default MonthPicker;
