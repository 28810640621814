import React, { useContext, useEffect, useState } from 'react';
import { AiOutlineSearch } from 'react-icons/ai';
import { GiHamburgerMenu } from 'react-icons/gi';
import { Link } from 'react-router-dom';
import useFetch from '../hooks/useFetch';
import './Navbar.scss';
import { UserContext, UserDispatchContext } from '../context/UserContext';
import { logout } from '../actions/userActions';
import logo from '../utils/images/mip-logo-PRIMARY.png';

export const Navbar = () => {
  const path = `${process.env.REACT_APP_ENDPOINT}/primary-navigation-links`;
  const { data, error } = useFetch(path);

  const [navContent, setNavContent] = useState();

  useEffect(() => {
    const localData = localStorage.getItem('navData');
    if (data !== null) {
      localStorage.setItem('navData', JSON.stringify(data));
      setNavContent(data);
    } else {
      setNavContent(JSON.parse(localData));
    }
  }, [data]);

  const { userInfo } = useContext(UserContext);
  const dispatch = useContext(UserDispatchContext);

  const handleLogout = async (e) => {
    e.preventDefault();
    await logout(dispatch);
  };

  return (
    <>
      <nav className='navbar navbar-expand-lg navbar-white bg-white pt-4 pb-4 ps-3 pe-3'>
        <div className='container-fluid px-2'>
          <ul className='navbar-nav me-auto mb-2 mb-lg-0'>
            <Link to='/' className='navbar-brand align-middle d-lg-none'>
              <img style={{ width: '10rem' }} src={logo} alt='logo' />
            </Link>
          </ul>
          <button
            className='navbar-toggler float-right bg-white'
            type='button'
            data-bs-toggle='collapse'
            data-bs-target='#navbarSupportedContent'
            aria-controls='navbarSupportedContent'
            aria-expanded='false'
            aria-label='Toggle navigation'
          >
            <GiHamburgerMenu className='navbar-toggler-icon text-primary' />
          </button>
          <div
            className='collapse navbar-collapse '
            id='navbarSupportedContent'
          >
            <ul className='navbar-nav me-2 mb-2 mb-lg-0'>
              <Link
                to='/'
                className='navbar-brand align-middle d-none d-lg-block'
              >
                <img style={{ width: '10em' }} src={logo} alt='logo' />
              </Link>
            </ul>
            <ul className='navbar-nav mb-2 mb-lg-0 flex-wrap'>
              {/* <Search classes="d-lg-none px-4" /> */}
              {navContent &&
                navContent
                  .sort(function (a, b) {
                    return a.id - b.id;
                  })
                  .map((page) => (
                    <li className='nav-item dropdown mx-2' key={page.id}>
                      <div className='nav-link fw-bolder fs-6'>
                        {page.name.toUpperCase()}
                      </div>
                      <ul
                        className='dropdown-menu dropdown-menu-dark'
                        aria-labelledby='navbarDropdown'
                      >
                        <li>
                          <Link
                            className='dropdown-item fw-bold fs-7'
                            to={`/pages/route/${page.page.route}`}
                            target={page.page.openNewTab ? '_blank' : '_self'}
                          >
                            {page.name.toUpperCase()}
                          </Link>
                        </li>
                        {page.secondary_navigation_links.map((item) => (
                          <li id={item.route} key={item.route}>
                            <Link
                              className='dropdown-item fw-bold fs-7'
                              target={item.openNewTab ? '_blank' : '_self'}
                              to={`/pages/route/${item.route}`}
                            >
                              {item.name.toUpperCase()}
                            </Link>
                          </li>
                        ))}
                      </ul>
                    </li>
                  ))}
            </ul>
            <ul className='navbar-nav mb-2 mb-lg-0 ms-auto'>
              <li className='nav-item dropdown mx-2 d-none d-lg-block'>
                <Link className='nav-link' to='/search' role='button'>
                  <AiOutlineSearch className='mb-1' size='20' />
                </Link>
              </li>
              {userInfo ? (
                <li className='nav-item dropdown mx-2'>
                  <Link
                    className='nav-link fw-bolder fs-7 dropdown-toggle'
                    to={`#`}
                    id='userDropdown'
                    data-toggle='dropdown'
                    aria-haspopup='true'
                    aria-expanded='false'
                  >
                    Me
                  </Link>
                  <div
                    className='dropdown-menu dropdown-menu-dark'
                    aria-labelledby='userDropdown'
                  >
                    <Link className='dropdown-item fw-bold fs-7' to='/profile'>
                      Profile
                    </Link>
                    <button
                      className='dropdown-item fw-bold fs-7'
                      onClick={handleLogout}
                    >
                      Logout
                    </button>
                  </div>
                </li>
              ) : (
                <Link to='/pages/route/tenant-portal'>
                  <button className='btn rounded-3 fs-6 fw-bolder text-primary'>
                    <span>Tenant Venture Portal</span>
                  </button>
                </Link>
              )}
            </ul>
          </div>
        </div>
      </nav>
      {error && (
        <div className='alert alert-danger' role='alert'>
          navbar isn't loading correctly, please contact admin
        </div>
      )}
    </>
  );
};
