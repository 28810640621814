import React from "react";
import ImageCard from "./ImageCard";

const ImageCardSet = ({ content }) => {
  switch (content.numberOfColumns) {
    case "two":
      return (
        <div className="row gx-8 gy-6 mx-md-10 my-8">
          {content.header && (
            <h4
              className="text-primary fs-1 text-center fw-bolder pt-2"
              style={{ fontFamily: "Brandon Grotesque" }}
            >
              {content.header}
            </h4>
          )}
          {content.eventCards &&
            content.eventCards.map((item, index) => (
              <div className="col-12 col-lg-6">
                <ImageCard
                  text={item.tag}
                  key={index}
                  color={`bg-${item.color}`}
                  imgWidth={content.brandingImage ? "50%" : "100%"}
                  type={item.type === "contain" ? "contain" : "cover"}
                  imgHeight="35vh"
                  titlePaddingY="my-3"
                  link={item.link}
                  imgLink={item.picture}
                  altText={
                    item.picture?.alternativeText
                      ? item.picture.alternativeText
                      : ""
                  }
                  description={item.details}
                />
              </div>
            ))}
        </div>
      );
    default:
      return (
        <div className="row row-cols-xl-3 row-cols-lg-2 row-cols-md-1 g-3 mx-md-10 my-5">
          {content.header && (
            <h4
              className="text-primary fs-1 text-center fw-bolder pt-2"
              style={{ fontFamily: "Brandon Grotesque" }}
            >
              {content.header}
            </h4>
          )}
          {content.eventCards &&
            content.eventCards.map((item, index) => (
              <ImageCard
                text={item.tag}
                key={index}
                color={`bg-${item.color}`}
                imgWidth={content.brandingImage ? "50%" : "100%"}
                type={item.type === "contain" ? "contain" : "cover"}
                imgHeight="30vh"
                titlePaddingY="my-1"
                link={item.link}
                imgLink={item.picture}
                altText={
                  item.picture?.alternativeText
                    ? item.picture.alternativeText
                    : ""
                }
                description={item.details}
              />
            ))}
        </div>
      );
  }
};

export default ImageCardSet;
