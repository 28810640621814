import React, { useState, useEffect } from "react";
import useFetch from "../hooks/useFetch";
import NewsCard from "./NewsCard";

const News = ({ content }) => {
  const [news, setNews] = useState([]);
  const path = `${process.env.REACT_APP_ENDPOINT}/news`;
  const { data, error, loading } = useFetch(path);

  useEffect(() => {
    if (data) {
      setNews(data);
    }
  }, [loading, error, data]);

  return (
    <div className="d-flex mx-4 my-10 align-items-start flex-column">
      {content.header && (
        <div className="row px-md-10 w-100">
          <h2 className="text-center text-primary pb-3 px-4 fw-bolder">
            {content.header}
          </h2>
        </div>
      )}
      <div className={content.numberPerLine === 2 ? 'row px-md-10 row-cols-1 row-cols-lg-2 w-100' : 'row px-md-10 row-cols-1 row-cols-lg-1 w-100'}>
        {news.length > 0 &&
          news
            .sort(function (a, b) {
              return a.date - b.date;
            })
            .map((item, index) => <NewsCard content={item} key={index} />)}
      </div>
    </div>
  );
};

export default News;
