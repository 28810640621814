import React from 'react';
import PropTypes from 'prop-types';
import './ImageCardCaption.scss';
import ReactMarkdown from 'react-markdown';
import remarkBreaks from 'remark-breaks';
import ImageWithFallback from './ImageWithFallback';

const ImageCardCaption = ({
  text,
  color,
  imgLink,
  altText,
  description,
  type,
  imgHeight,
  link,
  animation,
  fontClasses,
  gapClasses,
}) => {
  return (
    <div className={gapClasses}>
      <div
        className={'card border-0 ' + color}
        style={{
          boxShadow:
            'rgba(0, 0, 0, 0.07) 0px 1px 2px, rgba(0, 0, 0, 0.07) 0px 2px 4px, rgba(0, 0, 0, 0.07) 0px 4px 8px, rgba(0, 0, 0, 0.07) 0px 8px 16px, rgba(0, 0, 0, 0.07) 0px 16px 32px, rgba(0, 0, 0, 0.07) 0px 32px 64px',
        }}
      >
        {text && (
          <div
            className='d-flex justify-content-center align-items-center'
            style={{ minHeight: '88px' }}
          >
            <ReactMarkdown
              className={`${fontClasses} card-title fw-bolder text-center m-0`}
              remarkPlugins={[remarkBreaks]}
              components={{ p: 'span' }}
            >
              {text.toUpperCase()}
            </ReactMarkdown>
          </div>
        )}
        <ImageWithFallback
          src={`${process.env.REACT_APP_ENDPOINT}${
            imgLink.formats?.large && imgLink.formats.large.url
          }`}
          className='card-img-top'
          style={{
            width: '100%',
            maxHeight: `${imgHeight}`,
            height: `${imgHeight}`,
            objectPosition: '50% 20%',
            objectFit: `${type === 'contain' ? 'contain' : 'cover'}`,
          }}
          alt={altText}
          fallbackSrc={`${process.env.REACT_APP_ENDPOINT}${imgLink.url}`}
        />
        <div
          className='card-img-overlay d-flex flex-column justify-content-end px-0 pb-0'
          style={{ opacity: '85%', padding: '0' }}
        >
          {description && (
            <ReactMarkdown
              className={`card-text d-flex align-items-start ${
                animation ? 'active' : ''
              } fs-4 fw-bold lh-sm text-white bg-dark px-5 py-3`}
              remarkPlugins={[remarkBreaks]}
              components={{ p: 'span' }}
            >
              {description}
            </ReactMarkdown>
          )}
        </div>
        {link && (
          <a href={link} className='stretched-link'>
            <i className='fa fa-phone' aria-hidden='true'></i>{' '}
          </a>
        )}
      </div>
    </div>
  );
};

ImageCardCaption.propTypes = {
  text: PropTypes.string,
  color: PropTypes.string,
  imgLink: PropTypes.object,
  altText: PropTypes.string,
  description: PropTypes.string,
  type: PropTypes.string,
  imgHeight: PropTypes.string,
  link: PropTypes.string,
  animation: PropTypes.bool,
};

export default ImageCardCaption;
