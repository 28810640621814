import React from "react";
import ImageCardCaption from "./ImageCardCaption";

const ImageCardCaptionSet = ({ content }) => {
  switch (content.numberOfColumns) {
    case "two":
      return (
        <div>
          {content.header && (
            <div className="row px-md-10 w-100">
              <h2 className="text-center text-primary pb-3 px-4 fw-bolder fs-1">
                {content.header}
              </h2>
            </div>
          )}
          <div className="row row-cols-1 row-cols-lg-2 g-7 mx-md-10 mb-5">
            {content.displayCards &&
              content.displayCards.map((item, index) => (
                <ImageCardCaption
                  key={"ImageCaptionId" + item.id ? item.id : index}
                  text={item.tag}
                  color={`bg-${item.color}`}
                  type={item.type}
                  imgHeight="40vh"
                  animation={content.animation}
                  link={item.link}
                  imgLink={item.picture}
                  fontClasses="fs-4 px-4 py-3"
                  gapClasses="py-3 gx-7"
                  altText={
                    item.picture?.alternativeText
                      ? item.picture.alternativeText
                      : ""
                  }
                  description={item.details}
                />
              ))}
          </div>
        </div>
      );
    case "four":
      return (
        <div>
          {content.header && (
            <div className="row px-md-10 w-100">
              <h2 className="text-center text-primary pb-3 px-4 fw-bolder fs-1">
                {content.header}
              </h2>
            </div>
          )}
          <div className="row row-cols-lg-4 gy-3 mx-md-10 mb-5">
            {content.displayCards &&
              content.displayCards.map((item, index) => (
                <ImageCardCaption
                  key={"ImageCaptionId" + item.id ? item.id : index}
                  text={item.tag}
                  color={`bg-${item.color}`}
                  type={item.type}
                  link={item.link}
                  imgHeight="30vh"
                  animation={content.animation}
                  fontClasses="fs-5 px-3 py-3"
                  gapClasses="py-3 gx-7"
                  imgLink={item.picture}
                  altText={
                    item.picture?.alternativeText
                      ? item.picture.alternativeText
                      : ""
                  }
                  description={item.details}
                />
              ))}
          </div>
        </div>
      );
    case "five":
      return (
        <div>
          {content.header && (
            <div className="row px-md-10 w-100">
              <h2 className="text-center text-primary pb-3 px-4 fw-bolder fs-1">
                {content.header}
              </h2>
            </div>
          )}
          <div className="row row-cols-lg-5 gy-2 mx-md-10 mx-2 px-md-4 mb-5">
            {content.displayCards &&
              content.displayCards.map((item, index) => (
                <ImageCardCaption
                  key={"ImageCaptionId" + item.id ? item.id : index}
                  text={item.tag}
                  color={`bg-${item.color}`}
                  type={item.type}
                  link={item.link}
                  imgHeight="30vh"
                  animation={content.animation}
                  imgLink={item.picture}
                  fontClasses="fs-6 px-2 py-2"
                  gapClasses="py-3 gx-4"
                  altText={
                    item.picture?.alternativeText
                      ? item.picture.alternativeText
                      : ""
                  }
                  description={item.details}
                />
              ))}
          </div>
        </div>
      );
    default:
      return (
        <div>
          {content.header && (
            <div className="row px-md-10 w-100">
              <h2 className="text-center text-primary pb-3 px-4 fw-bolder fs-1">
                {content.header}
              </h2>
            </div>
          )}
          <div className="row row-cols-xl-3 row-cols-lg-2 row-cols-md-1 gy-3 mx-md-10 mb-5">
            {content.displayCards &&
              content.displayCards.map((item, index) => (
                <ImageCardCaption
                  key={"ImageCaptionId" + item.id ? item.id : index}
                  text={item.tag}
                  color={`bg-${item.color}`}
                  type={item.type}
                  link={item.link}
                  imgHeight="35vh"
                  animation={content.animation}
                  imgLink={item.picture}
                  fontClasses="fs-4 px-4 py-3"
                  gapClasses="py-3 gx-7"
                  altText={
                    item.picture?.alternativeText
                      ? item.picture.alternativeText
                      : ""
                  }
                  description={item.details}
                />
              ))}
          </div>
        </div>
      );
  }
};

export default ImageCardCaptionSet;
