import React, { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import ReactMarkdown from "react-markdown";
import remarkBreaks from "remark-breaks";
import NewsCard from "../components/NewsCard";
import useFetch from "../hooks/useFetch";
import Meta from "../components/Meta";
import ImageWithFallback from "../components/ImageWithFallback";
import {
  EmailIcon,
  FacebookIcon,
  TwitterShareButton,
  EmailShareButton,
  TwitterIcon,
  FacebookShareButton,
} from "react-share";

function NewsPage() {
  const { id } = useParams();
  const path = `${process.env.REACT_APP_ENDPOINT}/news/${id}`;
  const recentNewsPath = `${process.env.REACT_APP_ENDPOINT}/news`;
  const { data, loading, error } = useFetch(path);
  const recentNewsData = useFetch(recentNewsPath);

  const [news, setNews] = useState();
  const [recentNews, setRecentNews] = useState([]);

  useEffect(() => {
    if (data) {
      setNews(data);
    }
    if (recentNewsData.data) {
      setRecentNews(recentNewsData.data);
    }
  }, [loading, error, data, recentNewsData]);

  return (
    <>
      <div className="container-fluid px-md-10 pb-10 pt-5">
        {news && (
          <>
            <Meta
              title={news.title}
              description={news.content}
              image={`${process.env.REACT_APP_ENDPOINT}${news.cover.url}`}
              url={`${process.env.REACT_APP_ENDPOINT}/news/${id}`}
            />
            <div className="row" style={{ fontFamily: "Brandon Grotesque" }}>
              <div className="col-lg-3"></div>
              <div className="col-lg-6 overflow-hidden">
                {news.title && (
                  <h3 className="text-dark text-left fs-3 mb-4 fw-bolder">
                    {news.title}
                  </h3>
                )}
                {news.cover && (
                  <ImageWithFallback
                    src={`${process.env.REACT_APP_ENDPOINT}${
                      news.cover.formats.large && news.cover.formats.large.url
                    }`}
                    style={{
                      objectFit: "cover",
                      width: "100%",
                      height: "418px",
                    }}
                    alt={news.cover.caption}
                    fallbackSrc={`${process.env.REACT_APP_ENDPOINT}${news.cover.url}`}
                  />
                )}
                <div className="d-flex align-items-center justify-content-between mt-4 mb-5">
                  {news.date && (
                    <div
                      className="fs-6 py-1 fw-bolder"
                      style={{ color: "#b0b0b0" }}
                    >
                      {news.date}
                    </div>
                  )}
                  <div className="d-flex align-items-center">
                    <span className="fw-bolder me-3">SHARE</span>
                    <FacebookShareButton
                      windowWidth={750}
                      windowHeight={600}
                      url={`${process.env.REACT_APP_ENDPOINT}/news/${news.id}`}
                      className="mx-1"
                    >
                      <FacebookIcon size={32} round />
                    </FacebookShareButton>
                    <TwitterShareButton
                      title={news.title}
                      windowWidth={750}
                      windowHeight={600}
                      url={`${process.env.REACT_APP_ENDPOINT}/news/${news.id}`}
                      className="mx-1"
                    >
                      <TwitterIcon size={32} round />
                    </TwitterShareButton>
                    {/* <LinkedinShareButton
                      title={news.title}
                      summary={news.content.trim().split(/\r?\n/)[0]}
                      windowWidth={750}
                      windowHeight={600}
                      source={`${process.env.REACT_APP_ENDPOINT}/news/${news.id}`}
                      url={`${process.env.REACT_APP_ENDPOINT}/news/${news.id}`}
                      className="mx-1"
                    >
                      <LinkedinIcon size={"2rem"} round />
                    </LinkedinShareButton> */}
                    <EmailShareButton
                      subject={news.title}
                      body={news.content}
                      className="mx-1"
                    >
                      <EmailIcon size={"2rem"} round />
                    </EmailShareButton>
                  </div>
                </div>
                {news.content && (
                  <div>
                    <ReactMarkdown
                      className="fs-5 bg-transparent text-dark"
                      remarkPlugins={[remarkBreaks]}
                      linkTarget="_blank"
                    >
                      {news.content}
                    </ReactMarkdown>
                  </div>
                )}
                {news.link && (
                  <div className="mt-5">
                    <a
                      href={news.link}
                      className="text-dark fs-5"
                      target="_blank"
                      rel="noreferrer noopener"
                    >
                      Read more
                    </a>
                  </div>
                )}
              </div>
              <div className="col-lg-3"></div>
            </div>
          </>
        )}
        <div className="px-md-10 mt-10">
          <hr style={{ borderTop: "8px solid #bbb" }} />
        </div>
        <div className={"row px-md-10 row-cols-1 row-cols-lg-1 w-100 mt-10"}>
          <h3
            className="fw-bold text-dark"
            style={{ fontFamily: "Brandon Grotesque" }}
          >
            Recent Stories
          </h3>
          {recentNews.length > 0 &&
            recentNews
              .sort(function (a, b) {
                return a.date - b.date;
              })
              .slice(0, 2)
              .map((item, index) => <NewsCard content={item} key={index} />)}
        </div>
      </div>
    </>
  );
}

export default NewsPage;
