import axios from "axios";
import {
  USER_LOGIN_REQUEST,
  USER_LOGIN_SUCCESS,
  USER_SIGNIN_SUCCESS,
  USER_LOGIN_FAIL,
  USER_LOGOUT,
  USER_REGISTER_REQUEST,
  USER_REGISTER_SUCCESS,
  USER_REGISTER_FAIL,
  USER_UPDATE_REQUEST,
  USER_UPDATE_SUCCESS,
  USER_UPDATE_FAIL,
  USER_UPDATE_PASSWORD_REQUEST,
  USER_UPDATE_PASSWORD_SUCCESS,
  USER_UPDATE_PASSWORD_FAIL,
  USER_VERIFY_EMAIL_REQUEST,
  USER_VERIFY_EMAIL_FAIL,
  USER_VERIFY_EMAIL_SUCCESS,
  USER_RESET_PASSWORD_REQUEST,
  USER_RESET_PASSWORD_SUCCESS,
  USER_RESET_PASSWORD_FAIL,
  USER_FORGOT_PASSWORD_REQUEST,
  USER_FORGOT_PASSWORD_SUCCESS,
  USER_FORGOT_PASSWORD_FAIL,
  EMAIL_ECO_SYSTEM_REQUEST,
  EMAIL_ECO_SYSTEM_SUCCESS,
  EMAIL_ECO_SYSTEM_FAIL,
  EMAIL_LEASING_REQUEST,
  EMAIL_LEASING_SUCCESS,
  EMAIL_LEASING_FAIL
} from "../constants/userConstants";

// User signin action
export const signin = async (dispatch, loginPayload) => {
  const { email, password } = loginPayload;

  try {
    dispatch({
      type: USER_LOGIN_REQUEST,
    });

    const config = {
      headers: {
        "Content-Type": "application/json",
      },
    };

    const { data } = await axios.post(
      `${process.env.REACT_APP_AUTH_ENDPOINT}/signin`,
      { email, password },
      config
    );

    dispatch({
      type: USER_LOGIN_SUCCESS,
      payload: data,
    });

    // save data to local storage
    localStorage.setItem("userInfo", JSON.stringify(data));

    return JSON.stringify(data);
  } catch (err) {
    dispatch({
      type: USER_LOGIN_FAIL,
      payload:
        err.response && err.response.data.data
          ? err.response.data.data
          : err.message,
    });
  }
};

export const login = async (dispatch, loginPayload) => {
  const { email, password, samlRequest } = loginPayload;

  try {
    dispatch({
      type: USER_LOGIN_REQUEST,
    });

    const config = {
      headers: {
        "Content-Type": "application/x-www-form-urlencoded",
      },
    };

    const params = new URLSearchParams();
    params.append("Email", email);
    params.append("Password", password);
    params.append("SAMLRequest", encodeURIComponent(samlRequest));

    const { data } = await axios.post(
      `${process.env.REACT_APP_AUTH_ENDPOINT}/login`,
      params,
      config
    );

    dispatch({
      type: USER_SIGNIN_SUCCESS,
      payload: data,
    });

    // save data to local storage
    // localStorage.setItem("userInfo", JSON.stringify(data));

    return data;
  } catch (err) {
    dispatch({
      type: USER_LOGIN_FAIL,
      payload:
        err.response && err.response.data.data
          ? err.response.data.data
          : err.message,
    });
  }
};

// Register User Action
export const register = async (dispatch, registerPayload) => {
  const { firstName, lastName, password, email } = registerPayload;
  try {
    dispatch({
      type: USER_REGISTER_REQUEST,
    });

    const config = {
      headers: {
        "Content-Type": "application/json",
      },
    };

    // data from backend server
    const { data } = await axios.post(
      `${process.env.REACT_APP_AUTH_ENDPOINT}/register`,
      {
        first_name: firstName,
        last_name: lastName,
        email: email,
        password: password,
      },
      config
    );

    dispatch({
      type: USER_REGISTER_SUCCESS,
    });
    return data.success;
  } catch (err) {
    dispatch({
      type: USER_REGISTER_FAIL,
      payload:
        err.response && err.response.data.data
          ? err.response.data.data
          : err.message,
    });
  }
};

// verify email
export const verifyEmail = async (dispatch, payload) => {
  const { token } = payload;
  try {
    dispatch({
      type: USER_VERIFY_EMAIL_REQUEST,
    });

    const config = {
      headers: {
        "Content-Type": "application/json",
      },
    };

    const { data } = await axios.post(
      `${process.env.REACT_APP_AUTH_ENDPOINT}/confirmEmail`,
      {
        token,
      },
      config
    );
    dispatch({
      type: USER_VERIFY_EMAIL_SUCCESS,
    });
    dispatch({
      type: USER_LOGIN_SUCCESS,
      payload: data,
    });
    // save data to local storage
    localStorage.setItem("userInfo", JSON.stringify(data));
    return JSON.stringify(data);
  } catch (err) {
    dispatch({
      type: USER_VERIFY_EMAIL_FAIL,
      payload:
        err.response && err.response.data.data
          ? err.response.data.data
          : err.message,
    });
  }
};

// update user information
export const updateUser = async (dispatch, payload) => {
  const { firstName, lastName } = payload;
  try {
    dispatch({
      type: USER_UPDATE_REQUEST,
    });

    const config = {
      headers: {
        "Content-Type": "application/json",
      },
    };

    const { data } = await axios.post(
      `${process.env.REACT_APP_AUTH_ENDPOINT}/update`,
      {
        first_name: firstName,
        last_name: lastName,
      },
      config
    );

    dispatch({
      type: USER_UPDATE_SUCCESS,
      payload: data,
    });

    localStorage.setItem("userInfo", JSON.stringify(data));
    return JSON.stringify(data);
  } catch (err) {
    dispatch({
      type: USER_UPDATE_FAIL,
      payload:
        err.response && err.response.data.data
          ? err.response.data.data
          : err.message,
    });
  }
};

// update user information from cookie
export const getUserInfo = async (dispatch) => {
  try {
    const { data } = await axios.get(
      `${process.env.REACT_APP_AUTH_ENDPOINT}/info`
    );

    dispatch({
      type: USER_UPDATE_SUCCESS,
      payload: data,
    });
    localStorage.setItem("userInfo", JSON.stringify(data));
  } catch (err) {
    dispatch({
      type: USER_UPDATE_FAIL,
      payload:
        err.response && err.response.data.data
          ? err.response.data.data
          : err.message,
    });
  }
};

// update user password
export const updatePassword = async (dispatch, passwordPayload) => {
  try {
    dispatch({
      type: USER_UPDATE_PASSWORD_REQUEST,
    });

    const { currentPassword, newPassword } = passwordPayload;

    const config = {
      headers: {
        "Content-Type": "application/json",
      },
    };

    const { data } = await axios.post(
      `${process.env.REACT_APP_AUTH_ENDPOINT}/updatePass`,
      { old_pass: currentPassword, new_pass: newPassword },
      config
    );

    dispatch({
      type: USER_UPDATE_PASSWORD_SUCCESS,
      payload: data,
    });

    return JSON.stringify(data);
  } catch (err) {
    dispatch({
      type: USER_UPDATE_PASSWORD_FAIL,
      payload:
        err.response && err.response.data.data
          ? err.response.data.data
          : err.message,
    });
  }
};

// forgot password
export const forgotPassword = async (dispatch, passwordPayload) => {
  try {
    dispatch({
      type: USER_FORGOT_PASSWORD_REQUEST,
    });

    const { email } = passwordPayload;

    const config = {
      headers: {
        "Content-Type": "application/json",
      },
    };

    const { data } = await axios.post(
      `${process.env.REACT_APP_AUTH_ENDPOINT}/forgotPass`,
      { email },
      config
    );

    dispatch({
      type: USER_FORGOT_PASSWORD_SUCCESS,
      payload: data,
    });

    return data;
  } catch (err) {
    dispatch({
      type: USER_FORGOT_PASSWORD_FAIL,
      payload:
        err.response && err.response.data.data
          ? err.response.data.data
          : err.message,
    });
  }
};

export const resetPassword = async (dispatch, passwordPayload) => {
  try {
    dispatch({
      type: USER_RESET_PASSWORD_REQUEST,
    });

    const { newPassword, token } = passwordPayload;

    const config = {
      headers: {
        "Content-Type": "application/json",
      },
    };

    const { data } = await axios.post(
      `${process.env.REACT_APP_AUTH_ENDPOINT}/resetPass`,
      { new_pass: newPassword, token },
      config
    );

    dispatch({
      type: USER_RESET_PASSWORD_SUCCESS,
      payload: data,
    });

    return data;
  } catch (err) {
    dispatch({
      type: USER_RESET_PASSWORD_FAIL,
      payload:
        err.response && err.response.data.data
          ? err.response.data.data
          : err.message,
    });
  }
};

// User logout action
export const logout = async (dispatch) => {
  const res = await axios.get(`${process.env.REACT_APP_AUTH_ENDPOINT}/logout`);
  if (res.status === 200) {
    localStorage.removeItem("userInfo");
    dispatch({
      type: USER_LOGOUT,
    });
  }
};

// POST emailLeasing action
export const emailLeasing = async (dispatch, emailLeasingPayload) => {
  const { email, firstName, lastName, title, phone, desiredSQFT, comments } =
    emailLeasingPayload;

  try {
    dispatch({
      type: EMAIL_LEASING_REQUEST,
    });

    const config = {
      headers: {
        "Content-Type": "application/json",
      },
    };

    const { data } = await axios.post(
      `${process.env.REACT_APP_AUTH_ENDPOINT}/emailLeasing`,
      {
        email,
        firstName,
        lastName,
        title,
        phone,
        desiredSQFT,
        comments,
      },
      config
    );

    dispatch({
      type: EMAIL_LEASING_SUCCESS,
      payload: data,
    });

    return JSON.stringify(data);
  } catch (err) {
    dispatch({
      type: EMAIL_LEASING_FAIL,
      payload:
        err.response && err.response.data.data
          ? err.response.data.data
          : err.message,
    });
  }
};

// POST emailEcoSystem action
export const emailEcoSystem = async (dispatch, emailEcoSystemPayload) => {
  const {
    email,
    firstName,
    lastName,
    companyName,
    phone,
    businessType,
    comments,
  } = emailEcoSystemPayload;

  try {
    dispatch({
      type: EMAIL_ECO_SYSTEM_REQUEST,
    });

    const config = {
      headers: {
        "Content-Type": "application/json",
      },
    };

    const { data } = await axios.post(
      `${process.env.REACT_APP_AUTH_ENDPOINT}/emailEcoSystem`,
      {
        email,
        firstName,
        lastName,
        companyName,
        phone,
        businessType,
        comments,
      },
      config
    );

    dispatch({
      type: EMAIL_ECO_SYSTEM_SUCCESS,
      payload: data,
    });

    return JSON.stringify(data);
  } catch (err) {
    dispatch({
      type: EMAIL_ECO_SYSTEM_FAIL,
      payload:
        err.response && err.response.data.data
          ? err.response.data.data
          : err.message,
    });
  }
};
