import React, { useState, useEffect, useContext } from "react";
import logo from "../utils/images/mip-logo-PRIMARY.png";
import "./RegisterPage.scss";
import { Link } from "react-router-dom";
import { Formik } from "formik";
import { FaCheckCircle } from "react-icons/fa";
import { BsCheckCircle } from "react-icons/bs";
import * as yup from "yup";
import { register } from "../actions/userActions";
import { UserContext, UserDispatchContext } from "../context/UserContext";
import { Toast } from "bootstrap";

const RegisterPage = ({ history }) => {
  const schema = yup.object().shape({
    firstName: yup
      .string()
      .min(2, "Must be more than two characters long")
      .required("Must be filled out"),
    lastName: yup
      .string()
      .min(2, "Must be more than two characters long")
      .required("Must be filled out"),
    email: yup
      .string()
      .email("Invalid email address")
      .required("Must be filled out"),
    password: yup
      .string()
      .min(6, "Must be more than six characters long")
      .required("Must be filled out"),
    confirmPassword: yup
      .string()
      .oneOf([yup.ref("password"), null], "Passwords must match")
      .required("Must be filled out"),
  });

  const [open, setOpen] = useState(false);

  const [registerStatus, setRegisterStatus] = useState(false);

  const dispatch = useContext(UserDispatchContext);

  const { loading, error, userInfo } = useContext(UserContext);

  const handleSubmit = async (values) => {
    const { firstName, lastName, email, password } = values;
    let payload = {};
    payload.lastName = lastName;
    payload.password = password;
    payload.email = email;
    payload.firstName = firstName;
    try {
      setOpen(false);
      setRegisterStatus(false);
      const data = await register(dispatch, payload);
      console.log(data);
      if (data) {
        setRegisterStatus(true);
      } else setOpen(true);
    } catch (err) {
      console.log(err);
    }
    setOpen(true);
  };

  useEffect(() => {
    if (userInfo) {
      history.push("/");
    }
    if (!registerStatus) {
      let toast = new Toast(document.getElementById("errorToastRegister"));
      if (open) {
        toast.show();
      } else {
        toast.hide();
      }
    }
  }, [open, registerStatus, userInfo, history]);

  return (
    <div className="container vh-100">
      <div className="row border border-top-0 border-bottom-0 border-grey d-flex mx-md-10 h-100 align-items-start ">
        <div className="col-lg-6  pt-10 d-flex flex-column px-3">
          <Link to="/" className="navbar-brand">
            <img
              style={{ width: "10em" }}
              className="pt-5"
              src={logo}
              alt="logo"
            />
          </Link>
          <div className="d-none d-lg-block">
            <div className="mt-4 me-5 d-flex">
              <div className="me-2">
                <FaCheckCircle className="text-secondary" />
              </div>
              <div>
                <p className="text-dark fs-6 fw-bold mb-0">
                  BECOME A TENANT VENTURE
                </p>
                <p className="text-dark fs-6">
                  Our tenant ventures enjoy the benefits of a diverse business
                  community. Learn how you can join our ecosystem and make MIP
                  your home. Our state-of-the-art facilities are designed to
                  meet your needs- from a single desk space to build options.
                  Learn more about joining our community.
                </p>
              </div>
            </div>
            <div className="mt-4 me-5 d-flex">
              <div className="me-2">
                <FaCheckCircle className="text-secondary" />
              </div>
              <div>
                <p className="text-dark fs-6 fw-bold mb-0">TOUR THE PARK</p>
                <p className="text-dark fs-6">
                  We offer informational tours Monday to Friday. Book your tour
                  today and experience the magic of the park!
                </p>
              </div>
            </div>
            <div className="mt-4 me-5 d-flex">
              <div className="me-2">
                <FaCheckCircle className="text-secondary" />
              </div>
              <div>
                <p className="text-dark fs-6 fw-bold mb-0">BOOK YOUR EVENT</p>
                <p className="text-dark fs-6">
                  Looking for a venue for your next meeting, workshop, or
                  conference? Our conference facility has a variety of
                  capabilities to fit your needs.
                </p>
              </div>
            </div>
          </div>
        </div>
        <div className="col-lg-6 pt-5 px-7 border-left rightBlock h-100 bg-white">
          {registerStatus ? (
            <div className="d-flex flex-column align-items-center mt-10">
              <BsCheckCircle className="text-secondary display-3 mb-4" />
              <p className="fs-2 fw-bolder mb-4">REGISTRATION SUCCESS</p>
              <div className="alert alert-success" role="alert">
                <p className="fs-6 fw-bold">
                  <FaCheckCircle /> Thank you! We have sent you a verification
                  email. Please click the link in your email to activate your
                  account.
                </p>
              </div>
            </div>
          ) : (
            <>
              <div className="row mb-4 mt-10">
                <div className="col">
                  <h2 className="text-dark">Create you MIP Account</h2>
                </div>
              </div>
              <Formik
                validationSchema={schema}
                onSubmit={handleSubmit}
                initialValues={{
                  firstName: "",
                  lastName: "",
                  email: "",
                  password: "",
                  confirmPassword: "",
                }}
              >
                {({
                  handleSubmit,
                  handleChange,
                  handleBlur,
                  values,
                  touched,
                  errors,
                }) => (
                  <form onSubmit={handleSubmit}>
                    <div className="form-group mb-4">
                      <label htmlFor="email" className="text-dark fw-bold">
                        Email Address
                      </label>
                      <input
                        type="email"
                        className="form-control w-100 bg-transparent form-input"
                        id="email"
                        value={values.email}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        placeholder="Enter email"
                      />
                      {touched.email && errors.email ? (
                        <p className="text-primary fs-7">{errors.email}</p>
                      ) : null}
                    </div>
                    <div className="form-group mb-4">
                      <label htmlFor="firstName" className="text-dark fw-bold">
                        First Name
                      </label>
                      <input
                        type="text"
                        className="form-control w-100 bg-transparent form-input"
                        id="firstName"
                        value={values.firstName}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        placeholder="Enter first name"
                      />
                      {touched.firstName && errors.firstName ? (
                        <p className="text-primary fs-7">{errors.firstName}</p>
                      ) : null}
                    </div>

                    <div className="form-group mb-4">
                      <label htmlFor="lastName" className="text-dark fw-bold">
                        Last Name
                      </label>
                      <input
                        type="text"
                        className="form-control w-100 bg-transparent form-input"
                        id="lastName"
                        value={values.lastName}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        placeholder="Enter last name"
                      />
                      {touched.lastName && errors.lastName ? (
                        <p className="text-primary fs-7">{errors.lastName}</p>
                      ) : null}
                    </div>

                    <div className="form-group mb-4">
                      <label htmlFor="password" className="text-dark fw-bold">
                        Password
                      </label>
                      <input
                        type="password"
                        name="password"
                        className="form-control w-100 bg-transparent form-input"
                        id="password"
                        value={values.password}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        placeholder="Enter password"
                      />
                      {touched.password && errors.password ? (
                        <p className="text-primary fs-7">{errors.password}</p>
                      ) : null}
                    </div>

                    <div className="form-group mb-4">
                      <label
                        htmlFor="confirmPassword"
                        className="text-dark fw-bold"
                      >
                        Confirm Password
                      </label>
                      <input
                        type="password"
                        name="confirmPassword"
                        className="form-control w-100 bg-transparent form-input"
                        id="confirmPassword"
                        value={values.confirmPassword}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        placeholder="Confirm password"
                      />
                      {touched.confirmPassword && errors.confirmPassword ? (
                        <p className="text-primary fs-7">
                          {errors.confirmPassword}
                        </p>
                      ) : null}
                    </div>
                    <button
                      type="submit"
                      className="bg-info border-0 mt-2 text-white w-100 py-2"
                    >
                      {loading ? (
                        <div
                          className="spinner-border spinner-border-sm"
                          role="status"
                        >
                          <span className="visually-hidden">Loading...</span>
                        </div>
                      ) : (
                        "Create Account"
                      )}
                    </button>
                  </form>
                )}
              </Formik>
              <div className="row justify-content-center mt-4">
                <div
                  id="errorToastRegister"
                  className="toast "
                  role="alert"
                  aria-live="assertive"
                  aria-atomic="true"
                >
                  <div className="toast-header">
                    <strong className="me-auto text-warning">{error}</strong>
                    <button
                      type="button"
                      className="btn-close text-end"
                      data-bs-dismiss="toast"
                      aria-label="Close"
                    ></button>
                  </div>
                </div>
              </div>
            </>
          )}
          {/* <p className="my-3 text-dark">
            Have an account?{" "}
            <button onClick={() => setSigninModal(true)} className="text-info bg-transparent border-0">
              <span>Log in</span>
            </button>
          </p>
          <SigninModal
            show={signinModal}
            backdrop={false}
            onHide={() => setSigninModal(false)}
          /> */}
        </div>
      </div>
    </div>
  );
};

export default RegisterPage;
