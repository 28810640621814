import React, { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import useFetch from '../hooks/useFetch';
import FlexibleContentModule from './FlexibleContentModule';
import Hero from './Hero';
import Meta from './Meta';

function PageContent() {
  const location = useLocation();
  const path =
    location.pathname === '/'
      ? `${process.env.REACT_APP_ENDPOINT}/pages/route/home`
      : `${process.env.REACT_APP_ENDPOINT}${location.pathname}`;

  const [pageContent, setPageContent] = useState();

  const { loading, error, data } = useFetch(path);

  useEffect(() => {
    const localData = localStorage.getItem('pageContent');
    if (data) {
      localStorage.setItem('pageContent', JSON.stringify(data));
      setPageContent(data);
    } else {
      setPageContent(JSON.parse(localData));
    }
  }, [data]);

  if (loading)
    return (
      <div className='d-flex justify-content-center'>
        <div className='spinner-border text-primary' role='status'></div>
      </div>
    );

  return (
    <div>
      <div className='flexible-content-modules'>
        <Meta />
        {pageContent && pageContent.banner && (
          <Hero contentModule={pageContent.banner} />
        )}
        {pageContent && pageContent.body
          ? pageContent.body.map((contentModule) => (
              <FlexibleContentModule
                key={contentModule.__component + contentModule.id}
                contentModule={contentModule}
              />
            ))
          : null}
        {error && (
          <div className='alert alert-danger' role='alert'>
            contents isn't loading correctly, please contact admin
          </div>
        )}
      </div>
    </div>
  );
}

export default PageContent;
