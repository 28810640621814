import React from "react";
import ReactMarkdown from "react-markdown";
import remarkBreaks from "remark-breaks";

const Accordion = ({ content }) => {
  return (
    <div className="px-md-10 px-4 py-6">
      {content.accordionTitle && (
        <div className="text-center text-primary fs-1 pb-3 px-4 fw-bolder">
          {content.accordionTitle}
        </div>
      )}
      {content.accordionItems && (
        <div
          className="accordion accordion-flush"
          style={{
            boxShadow:
              "rgba(0, 0, 0, 0.07) 0px 1px 2px, rgba(0, 0, 0, 0.07) 0px 2px 4px, rgba(0, 0, 0, 0.07) 0px 4px 8px, rgba(0, 0, 0, 0.07) 0px 8px 16px, rgba(0, 0, 0, 0.07) 0px 16px 32px, rgba(0, 0, 0, 0.07) 0px 32px 64px",
          }}
          id="accordionExample"
        >
          {content.accordionItems.map((location, index) => (
            <div className="accordion-item" key={index}>
              <h2 className="accordion-header" id={location.location}>
                <button
                  className="accordion-button collapsed fs-4 fw-bolder"
                  type="button"
                  style={{ boxShadow: "none" }}
                  data-bs-toggle="collapse"
                  data-bs-target={`#collapse-${location.id}`}
                  aria-expanded="false"
                  aria-controls={`collapse-${location.id}`}
                >
                  {location.location}
                </button>
              </h2>
              <div
                id={`collapse-${location.id}`}
                className="accordion-collapse collapse"
                aria-labelledby={location.location}
                data-bs-parent="#accordionExample"
              >
                <div className="accordion-body">
                  {location.generalContent ? (
                    <ReactMarkdown
                      className={`card-text fs-5 fw-bold lh-sm text-dark ps-2 py-2`}
                      remarkPlugins={[remarkBreaks]}
                      components={{ p: "span" }}
                      linkTarget="_blank"
                    >
                      {location.generalContent}
                    </ReactMarkdown>
                  ) : (
                    <div className="table-responsive">
                      <table className="table table-hover">
                        <thead>
                          <tr>
                            <th scope="col" className="fs-5">Company</th>
                            <th scope="col" className="fs-5">Website</th>
                            <th scope="col" className="fs-5">Suite</th>
                          </tr>
                        </thead>
                        <tbody>
                          {location.companys?.map((row) => (
                            <tr key={row.id}>
                              {row.name ? <th scope="row" className="fs-5">{row.name}</th> : <th scope="row"></th>}
                              {row.website ? (
                                <td className="text-dark fs-5">
                                  <a
                                    href={`//${row.website.trim()}`}
                                    target="_blank"
                                    rel="noreferrer"
                                    aria-label="website_url"
                                    style={{
                                      textDecoration: "none",
                                      color: "#212529",
                                    }}
                                  >
                                    {row.website.trim()}
                                  </a>
                                </td>
                              ): <td></td>}
                              {row.suite ? (
                                <td className="text-dark fs-5">{row.suite}</td>
                              ): <td></td>}
                            </tr>
                          ))}
                        </tbody>
                      </table>
                    </div>
                  )}
                </div>
              </div>
            </div>
          ))}
        </div>
      )}
    </div>
  );
};

export default Accordion;
