import React, { useState, useEffect, useContext } from "react";
import { useLocation } from "react-router-dom";
import resetPic from "../utils/images/reset.jpeg";
import { Formik } from "formik";
import * as yup from "yup";
import { resetPassword } from "../actions/userActions";
import { UserContext, UserDispatchContext } from "../context/UserContext";
import { Toast } from "bootstrap";
import "./SigninPage.scss";

function ResetPasswordPage({ history }) {
  const validationSchema = yup.object({
    password: yup
      .string()
      .min(6, "Must be more than six characters long")
      .required("Must be filled out"),
    confirmPassword: yup
      .string()
      .oneOf([yup.ref("password"), null], "Passwords must match")
      .required("Must be filled out"),
  });

  const dispatch = useContext(UserDispatchContext);

  const { loading, error } = useContext(UserContext);

  const [open, setOpen] = useState();

  const { search } = useLocation();

  const params = new URLSearchParams(search);
  const token = params.get("token");

  const handleSubmit = async (values) => {
    const { password } = values;
    let resetPayload = {};
    resetPayload.newPassword = password;
    resetPayload.token = token;
    setOpen(false);
    try {
      const data = await resetPassword(dispatch, resetPayload);
      if (data) {
        history.push("/signin");
      } else {
        setOpen(true);
      }
    } catch (err) {
      console.log(err);
    }
  };

  useEffect(() => {
    let toast = new Toast(document.getElementById("errorToastReset"));
    if (open) {
      toast.show();
    } else {
      toast.hide();
    }
    return () => {
      toast.dispose();
    };
  }, [open]);

  return (
    <div className="container-fluid px-0 overflow-hidden">
      <div className="row">
        <div className="col-lg-4 px-0">
          <img
            className="signin-left-image"
            src={resetPic}
            alt="modal-left-book"
          />
        </div>
        <div className="col-lg-8 d-flex flex-column signin-right-panel align-items-center bg-white justify-content-center">
          <h2 className="text-dark display-5">Reset Password</h2>
          <Formik
            initialValues={{
              password: "",
              confirmPassword: "",
            }}
            validationSchema={validationSchema}
            onSubmit={handleSubmit}
          >
            {({
              handleSubmit,
              handleChange,
              handleBlur,
              values,
              touched,
              errors,
            }) => (
              <form style={{ width: "60%" }} onSubmit={handleSubmit}>
                <div className="form-group mb-3 mt-2">
                  <label htmlFor="resetPassword" className="text-dark">
                    Password
                  </label>
                  <input
                    type="password"
                    name="password"
                    className="form-control w-100 bg-transparent form-input lh-lg"
                    id="resetPassword"
                    placeholder="Enter password"
                    value={values.password}
                    onChange={handleChange}
                    onBlur={handleBlur}
                  />
                  {touched.password && errors.password ? (
                    <p className="text-primary fs-7">{errors.password}</p>
                  ) : null}
                </div>
                <div className="form-group mb-4">
                  <label htmlFor="confirmPassword" className="text-dark">
                    Confirm Password
                  </label>
                  <input
                    type="password"
                    name="confirmPassword"
                    className="form-control w-100 bg-transparent form-input lh-lg"
                    value={values.confirmPassword}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    id="confirmPassword"
                    placeholder="Confirm password"
                  />
                  {touched.confirmPassword && errors.confirmPassword ? (
                    <p className="text-primary fs-7">
                      {errors.confirmPassword}
                    </p>
                  ) : null}
                </div>
                <button
                  type="submit"
                  className="bg-primary border-0 mt-2 lh-lg text-white w-100 py-1 submit-btn"
                >
                  {loading ? (
                    <div
                      className="spinner-border spinner-border-sm"
                      role="status"
                    >
                      <span className="visually-hidden">Loading...</span>
                    </div>
                  ) : (
                    "Save"
                  )}
                </button>
              </form>
            )}
          </Formik>
          <div
            id="errorToastReset"
            className="toast"
            role="alert"
            aria-live="assertive"
            aria-atomic="true"
          >
            <div className="toast-header">
              <strong className="me-auto text-warning">{error}</strong>
              <button
                type="button"
                className="btn-close text-end"
                data-bs-dismiss="toast"
                aria-label="Close"
              ></button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default ResetPasswordPage;
