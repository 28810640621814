import React, { useState } from 'react';
import EmbedVideo from './EmbedVideo';
import FormModal from './FormModal';
import ReactMarkdown from 'react-markdown';
import Carousel from './Carousel';
import remarkBreaks from 'remark-breaks';
import ImageWithFallback from './ImageWithFallback';

const renderContent = (content, remarkBreaks) => (
  <ReactMarkdown
    className='fs-3 fw-bold text-dark lh-sm mb-5 regular-text'
    children={content}
    linkTarget='_blank'
    remarkPlugins={[remarkBreaks]}
  />
);

const IconTextButton = ({ content }) => {
  const [openForm, setOpenForm] = useState(false);

  const ButtonForm = ({ btn }) => {
    if (btn.openForm) {
      return (
        <React.Fragment>
          <button
            onClick={() => setOpenForm(true)}
            style={{
              boxShadow:
                'rgba(50, 50, 93, 0.25) 0px 2px 5px -1px, rgba(0, 0, 0, 0.3) 0px 1px 3px -1px',
            }}
            className={`btn bg-${
              btn.color ? btn.color : 'primary'
            }  fw-bolder btn-lg text-white px-4 my-1 rounded-3 mx-1 fs-4`}
          >
            {btn.name.toUpperCase()}
          </button>
          <FormModal
            show={openForm}
            backdrop={true}
            onHide={() => setOpenForm(false)}
            type={btn.formType}
          />
        </React.Fragment>
      );
    } else if (btn.toggleCollapse) {
      return (
        <button
          className={`btn bg-${
            btn.color ? btn.color : 'primary'
          }  fw-bolder btn-lg text-white px-4 my-1 rounded-3 mx-1 fs-4`}
          data-bs-toggle='collapse'
          style={{
            boxShadow:
              'rgba(50, 50, 93, 0.25) 0px 2px 5px -1px, rgba(0, 0, 0, 0.3) 0px 1px 3px -1px',
          }}
          data-bs-target={`#${btn.collapseTarget}`}
          aria-expanded='false'
          aria-controls={btn.collapseTarget}
        >
          {btn.name.toUpperCase()}
        </button>
      );
    } else {
      return (
        <a
          href={btn.Link}
          target='_blank'
          rel='noreferrer noopener'
          type='button'
          style={{
            boxShadow:
              'rgba(50, 50, 93, 0.25) 0px 2px 5px -1px, rgba(0, 0, 0, 0.3) 0px 1px 3px -1px',
          }}
          className={`btn bg-${
            btn.color ? btn.color : 'primary'
          }  fw-bolder btn-lg text-white px-4 my-1 rounded-3 mx-1 fs-4`}
        >
          {btn.name.toUpperCase()}
        </a>
      );
    }
  };

  switch (content.direction) {
    case 'rtl':
      return (
        <div
          className='row d-flex flex-row align-items-start p-4 px-10'
          id={content.blockID}
        >
          <div
            className={
              content.media.type === 'video'
                ? 'col-xl-7 col-lg-12 px-5 align-self-center'
                : 'col-xl-7 col-lg-12 pb-3'
            }
          >
            <ReactMarkdown
              className={`${
                content.media.type === 'video' ? 'text-dark' : 'text-primary'
              } fs-1 fw-bolder mb-4`}
              children={content.title}
              remarkPlugins={[remarkBreaks]}
            />
            {renderContent(content.content, remarkBreaks)}
            {content.button &&
              content.button.map((btn) => (
                <ButtonForm key={btn.id} btn={btn} />
              ))}
          </div>
          {content.media.type === 'video' ? (
            <div className='col-xl-5 col-lg-12 px-5'>
              <EmbedVideo src={content.media.src} />
            </div>
          ) : content.media.type === 'carousel' ||
            content.media.media.length > 1 ? (
            <div className='col-xl-5 col-lg-12'>
              <Carousel contentModule={content.media.media} name='iconText' />
            </div>
          ) : (
            <div className='col-xl-5 col-lg-12'>
              <ImageWithFallback
                src={`${process.env.REACT_APP_ENDPOINT}${
                  content.media.media[0].formats?.large &&
                  content.media.media[0].formats.large.url
                }`}
                style={{
                  width: '100%',
                  height: '100%',
                  maxHeight: '500px',
                  objectFit: `${content.media.contain ? 'contain' : 'cover'}`,
                  objectPosition: 'center center',
                }}
                alt={content.type}
                fallbackSrc={`${process.env.REACT_APP_ENDPOINT}${content.media.media[0].url}`}
              />
              {content.media.media[0].caption && (
                <p
                  className='text-dark fs-6 mt-2'
                  style={{ fontFamily: 'Brandon Grotesque', fontWeight: 500 }}
                >
                  {content.media.media[0].caption}
                </p>
              )}
            </div>
          )}
        </div>
      );
    default:
      return (
        <div
          className='row d-flex flex-row align-items-start pt-3 pb-8 px-10'
          id={content.blockID}
        >
          {content.media.type === 'video' ? (
            <div className='col-xl-5 col-lg-12 px-5'>
              <EmbedVideo src={content.media.src} />
            </div>
          ) : content.media.type === 'carousel' ||
            content.media.media.length > 1 ? (
            <div className='col-xl-5 col-lg-12 mb-2'>
              <Carousel contentModule={content.media.media} name='iconText' />
            </div>
          ) : (
            <div
              className='col-xl-5 col-lg-12 mb-2'
              style={{ overflow: 'hidden' }}
            >
              <ImageWithFallback
                src={`${process.env.REACT_APP_ENDPOINT}${
                  content.media.media[0].formats?.large &&
                  content.media.media[0].formats.large.url
                }`}
                style={{
                  width: '100%',
                  height: '100%',
                  maxHeight: '500px',
                  objectFit: `${content.media.contain ? 'contain' : 'cover'}`,
                  objectPosition: 'center center',
                }}
                alt={content.type}
                fallbackSrc={`${process.env.REACT_APP_ENDPOINT}${content.media.media[0].url}`}
              />
              {content.media.media[0].caption && (
                <p
                  className='text-dark mt-2 fs-3'
                  style={{ fontFamily: 'Brandon Grotesque', fontWeight: 500 }}
                >
                  {content.media.media[0].caption}
                </p>
              )}
            </div>
          )}
          <div
            className={
              content.media.type === 'video'
                ? 'col px-5 align-self-center'
                : 'col-xl-7 col-lg-12 px-xl-6 px-md-2'
            }
          >
            <ReactMarkdown
              className={`${
                content.media.type === 'video'
                  ? 'fs-1 text-dark align-self-center'
                  : 'fs-1 text-primary'
              } fw-bolder mb-4`}
              children={content.title}
              remarkPlugins={[remarkBreaks]}
            />
            {renderContent(content.content, remarkBreaks)}
            {content.button &&
              content.button.map((btn) => (
                <ButtonForm key={btn.id} btn={btn} />
              ))}
          </div>
        </div>
      );
  }
};

export default IconTextButton;
