import React, { useState, useEffect, useContext, useRef } from "react";
import { Link, useLocation } from "react-router-dom";
import mip from "../utils/images/mip.jpeg";
import { Formik } from "formik";
import * as yup from "yup";
import { login, signin, forgotPassword, getUserInfo } from "../actions/userActions";
import { UserContext, UserDispatchContext } from "../context/UserContext";
import { Toast, Modal } from "bootstrap";
import "./SigninPage.scss";

function SigninPage({ history }) {
  const validationSchema = yup.object({
    email: yup
      .string()
      .email("Invalid email format")
      .required("Email is Required"),
    password: yup
      .string()
      .required("Password is Required")
      .min(6, "Password too short"),
  });

  const dispatch = useContext(UserDispatchContext);

  const { loading, error, userInfo } = useContext(UserContext);

  const [open, setOpen] = useState();

  const [show, setShow] = useState(false);

  const [passwordModal, setPasswordModal] = useState([]);

  const [accountEmail, setAccountEmail] = useState();

  const [emailSent, setEmailSent] = useState(false);

  const { search } = useLocation();

  const [html, setHtml] = useState(null);

  const params = new URLSearchParams(search);
  const samlToken = params.get("SAMLRequest");

  const forgotPasswordModalRef = useRef();

  const handleSubmit = async (values) => {
    const { email, password } = values;
    let loginPayload = {};
    let signinPayload = {};
    signinPayload.email = email;
    signinPayload.password = password;
    loginPayload.password = password;
    loginPayload.email = email;
    setOpen(false);
    setHtml(null);

    try {
      if (search.includes("SAMLRequest=", 1)) {
        loginPayload.samlRequest = samlToken;
        const htmlResponse = await login(dispatch, loginPayload);
        if (htmlResponse) {
          setHtml({ __html: `${htmlResponse}` });
          await getUserInfo(dispatch);
        } else setOpen(true);
      } else {
        const user = await signin(dispatch, signinPayload);
        if (user && userInfo) {
          history.push("/");
        } else setOpen(true);
      }
    } catch (err) {
      console.log(err);
    }
  };

  const passwordModalOpen = () => {
    setEmailSent(false);
    setAccountEmail(null);
    setOpen(false);
    setShow(true);
  };

  const submitEmail = async () => {
    setEmailSent(false);
    let payload = {};
    payload.email = accountEmail;
    try {
      const data = await forgotPassword(dispatch, payload);
      if (data) {
        setEmailSent(true);
      } else {
        setShow(false);
        setOpen(true);
        passwordModal.hide();
      }
    } catch (err) {
      setShow(false);
      console.log(err);
    }
  };

  useEffect(() => {
    if (userInfo && !html) {
      history.push("/");
    }

    if (!html) {
      const forgotPassModal = document.getElementById("forgotPasswordModal");
      forgotPassModal.addEventListener("hidden.bs.modal", function (event) {
        setShow(false);
      });

      const modal = new Modal(forgotPasswordModalRef.current);
      setPasswordModal(modal);

      if (show) {
        modal.show();
      }  
    }

    const toast = new Toast(document.getElementById("errorToastLogin"));
   
    if (open) {
      toast.show();
    } else {
      toast.hide();
    }
    return () => {
      toast.hide();
    };
  }, [open, show, userInfo, history, html]);

  return (
    <div className="container-fluid px-0 overflow-hidden">
      <div className="row">
        <div className="col-lg-4 px-0">
          <img className="signin-left-image" src={mip} alt="modal-left-book" />
        </div>
        <div className="col-lg-8 d-flex flex-column signin-right-panel align-items-center bg-white justify-content-center">
          <h2 className="text-dark display-5">Welcome back!</h2>
          <p className="text-dark fs-6 fw-bold">To MIP</p>
          {html ? (
            <>
              <p className="text-dark" style={{ marginTop: "2rem" }}>
                Click the button below to be redirected back to QReserve page
              </p>
              <div dangerouslySetInnerHTML={html} />
            </>
          ) : (
            <>
              <Formik
                initialValues={{
                  email: "",
                  password: "",
                }}
                validationSchema={validationSchema}
                onSubmit={handleSubmit}
              >
                {({
                  handleSubmit,
                  handleChange,
                  handleBlur,
                  values,
                  touched,
                  errors,
                }) => (
                  <form style={{ width: "60%" }} onSubmit={handleSubmit}>
                    <div className="form-group mb-3 mt-2">
                      <label htmlFor="email" className="text-dark">
                        Email address
                      </label>
                      <input
                        type="email"
                        className="form-control w-100 bg-transparent form-input lh-lg"
                        id="email"
                        placeholder="Enter email"
                        value={values.email}
                        onChange={handleChange}
                        onBlur={handleBlur}
                      />
                      {touched.email && errors.email ? (
                        <p className="text-primary fs-7">{errors.email}</p>
                      ) : null}
                    </div>
                    <div className="form-group mb-4">
                      <label htmlFor="password" className="text-dark">
                        Password
                      </label>
                      <input
                        type="password"
                        className="form-control w-100 bg-transparent form-input lh-lg"
                        value={values.password}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        id="password"
                        placeholder="Enter password"
                      />
                      {touched.password && errors.password ? (
                        <p className="text-primary fs-7">{errors.password}</p>
                      ) : null}
                    </div>
                    <button
                      type="submit"
                      className="bg-primary border-0 mt-2 lh-lg text-white w-100 py-1 submit-btn"
                    >
                      {loading ? (
                        <div
                          className="spinner-border spinner-border-sm"
                          role="status"
                        >
                          <span className="visually-hidden">Loading...</span>
                        </div>
                      ) : (
                        "Submit"
                      )}
                    </button>
                  </form>
                )}
              </Formik>
              <p className="my-4 text-dark">
                Don't have an account yet?{" "}
                <Link
                  to="/register"
                  className="border-0 bg-transparent text-primary"
                >
                  Register
                </Link>
              </p>
              <p className="text-dark">
                Forgot your{" "}
                <u
                  className="text-primary"
                  style={{ cursor: "pointer" }}
                  onClick={passwordModalOpen}
                >
                  password?
                </u>
              </p>
              <div
                className="modal fade"
                id="forgotPasswordModal"
                tabIndex="-1"
                ref={forgotPasswordModalRef}
                aria-labelledby="forgotPasswordModalLabel"
                aria-hidden="true"
              >
                <div className="modal-dialog modal-dialog-centered">
                  <div className="modal-content">
                    <div className="modal-header">
                      <h5
                        className="modal-title"
                        id="forgotPasswordModalHeader"
                      >
                        Forgot Password
                      </h5>
                      <button
                        type="button"
                        className="btn-close"
                        data-bs-dismiss="modal"
                        aria-label="Close"
                      ></button>
                    </div>
                    {emailSent ? (
                      <div className="modal-body">
                        <p>
                          The password reset email has sent, please check your
                          email.
                        </p>
                      </div>
                    ) : (
                      <>
                        <div className="modal-body">
                          <label htmlFor="emailAddress" className="text-dark">
                            Email address
                          </label>
                          <input
                            type="email"
                            className="form-control w-100 bg-transparent form-input lh-lg"
                            id="emailAddress"
                            defaultValue={accountEmail}
                            onChange={(e) => setAccountEmail(e.target.value)}
                            placeholder="Enter email"
                          />
                        </div>
                        <div className="modal-footer">
                          <button
                            type="button"
                            className="btn btn-secondary"
                            data-bs-dismiss="modal"
                          >
                            Close
                          </button>
                          <button
                            type="button"
                            className="btn btn-primary"
                            onClick={submitEmail}
                          >
                            Continue
                          </button>
                        </div>
                      </>
                    )}
                  </div>
                </div>
              </div>
            </>
          )}
          <div
            id="errorToastLogin"
            className="toast"
            role="alert"
            aria-live="assertive"
            aria-atomic="true"
          >
            <div className="toast-header">
              <strong className="me-auto text-warning">{error}</strong>
              <button
                type="button"
                className="btn-close text-end"
                data-bs-dismiss="toast"
                aria-label="Close"
              ></button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default SigninPage;
