import React, {
  useEffect,
  useState,
  useRef,
  useContext,
  useCallback,
} from "react";
import { Modal, Toast } from "bootstrap";
import { emailEcoSystem, emailLeasing } from "../actions/userActions";
import { UserContext, UserDispatchContext } from "../context/UserContext";

const FormModal = ({ show, onHide, backdrop, type }) => {
  const [email, setEmail] = useState("");
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [title, setTitle] = useState("");
  const [phone, setPhone] = useState();
  const [company, setCompany] = useState("");
  const [businessType, setBusinessType] = useState("");
  const [desireSpace, setDesireSpace] = useState("");
  const [check, setCheck] = useState(false);
  const [comments, setComments] = useState("");

  const formModalRef = useRef();

  const { loading, error } = useContext(UserContext);

  const dispatch = useContext(UserDispatchContext);

  const submitLeasing = async () => {
    let payload = {};
    payload.lastName = lastName;
    payload.title = title;
    payload.email = email;
    payload.firstName = firstName;
    payload.phone = phone;
    payload.desiredSQFT = desireSpace;
    payload.comments = comments;

    const formInstance = document.getElementById("leasingForm");
    if (formInstance.checkValidity() === false) {
      // This is the magic function that displays the validation errors to the user
      formInstance.reportValidity();
      return;
    } else {
      try {
        const data = await emailLeasing(dispatch, payload);
        if (data) {
          hideModal();
        }
      } catch (err) {
        console.log(err);
      }
    }
  };

  const submitEcoSystem = async (e) => {
    let payload = {};
    payload.lastName = lastName;
    payload.businessType = businessType;
    payload.email = email;
    payload.firstName = firstName;
    payload.phone = phone;
    payload.companyName = company;
    payload.comments = comments;
    const formInstance = document.getElementById("ecoSystemForm");
    if (formInstance.checkValidity() === false) {
      // This is the magic function that displays the validation errors to the user
      formInstance.reportValidity();
      return;
    } else {
      try {
        const data = await emailEcoSystem(dispatch, payload);
        if (data) {
          hideModal();
        }
      } catch (err) {
        console.log(err);
      }
    }
  };

  const hideModal = () => {
    const modalEle = formModalRef.current;
    const bsModal = Modal.getInstance(modalEle);
    bsModal.hide();
  };

  const showModal = useCallback(() => {
    const modalEle = formModalRef.current;
    const bsModal = new Modal(modalEle, {
      backdrop: backdrop,
      keyboard: true,
    });
    bsModal.show();
  }, [backdrop]);

  useEffect(() => {
    const modalEL = document.getElementById("formModal");
    modalEL.addEventListener("hidden.bs.modal", function (event) {
      onHide();
    });
    const toastElList = document.querySelectorAll(".toast");
    [...toastElList].forEach((toastEl) => {
      const bsToast = new Toast(toastEl);
      bsToast.show();
    });

    if (show) {
      showModal();
    }
  }, [showModal, show, onHide]);

  switch (type) {
    case "PartnerForm":
      return (
        <div
          className="modal fade"
          id="formModal"
          tabIndex="-1"
          role="dialog"
          ref={formModalRef}
          aria-labelledby="singinModalRef"
          aria-hidden="true"
        >
          <div
            className="modal-dialog modal-lg modal-dialog-centered"
            role="document"
          >
            <div className="modal-content">
              <div className="modal-header p-0 bg-secondary">
                <h5
                  className="modal-title text-white px-5 py-4"
                  style={{ fontFamily: "Brandon Grotesque", fontWeight: 500 }}
                  id="exampleModalLongTitle"
                >
                  We look forward to partnering with you. Please answer a few
                  questions below to get us started in the right direction.
                </h5>
              </div>
              <div className="modal-body p-0">
                <div className="container-fluid px-0">
                  <div className="row">
                    <div className="col-lg-12 d-flex flex-column align-items-center justify-content-center">
                      <form className="w-100" id="ecoSystemForm">
                        <ul className="list-group list-group-flush">
                          <li className="list-group-item">
                            <div className="form-group">
                              <label
                                htmlFor="firstName"
                                className="text-black ps-2 fs-6"
                              >
                                First Name
                              </label>
                              <input
                                type="text"
                                className="form-control w-100 bg-transparent form-input border-0"
                                defaultValue={firstName}
                                onChange={(e) => setFirstName(e.target.value)}
                                id="firstName"
                                placeholder="Enter First Name"
                                required
                              />
                            </div>
                          </li>
                          <li className="list-group-item">
                            <div className="form-group">
                              <label
                                htmlFor="lastName"
                                className="text-black ps-2 fs-6"
                              >
                                Last Name
                              </label>
                              <input
                                type="text"
                                className="form-control w-100 bg-transparent form-input border-0"
                                defaultValue={lastName}
                                onChange={(e) => setLastName(e.target.value)}
                                id="lastName"
                                placeholder="Enter Last Name"
                                required
                              />
                            </div>
                          </li>
                          <div className="divider"></div>
                          <li className="list-group-item">
                            <div className="form-group">
                              <label
                                htmlFor="company"
                                className="text-black ps-2 fs-6"
                              >
                                Company Name
                              </label>
                              <input
                                type="text"
                                className="form-control w-100 bg-transparent form-input border-0"
                                defaultValue={company}
                                onChange={(e) => setCompany(e.target.value)}
                                id="company"
                                placeholder="Enter Company Name"
                              />
                            </div>
                          </li>
                          <li className="list-group-item">
                            <div className="form-group">
                              <label
                                htmlFor="businessType"
                                className="text-black ps-2 fs-6"
                              >
                                Type of Business
                              </label>
                              <input
                                type="text"
                                className="form-control w-100 bg-transparent form-input border-0"
                                defaultValue={businessType}
                                onChange={(e) =>
                                  setBusinessType(e.target.value)
                                }
                                id="businessType"
                                placeholder="Enter Type of Business"
                              />
                            </div>
                          </li>
                          <div className="divider"></div>
                          <li className="list-group-item">
                            <div className="form-group">
                              <label
                                htmlFor="email"
                                className="text-black ps-2 fs-6"
                              >
                                Email Address
                              </label>
                              <input
                                type="email"
                                className="form-control w-100 bg-transparent form-input border-0"
                                id="email"
                                defaultValue={email}
                                onChange={(e) => setEmail(e.target.value)}
                                aria-describedby="emailHelp"
                                placeholder="Enter Email"
                                required
                              />
                            </div>
                          </li>
                          <li className="list-group-item">
                            <div className="form-group">
                              <label
                                htmlFor="phone"
                                className="text-black ps-2 fs-6"
                              >
                                Phone
                              </label>
                              <input
                                type="tel"
                                className="form-control w-100 bg-transparent form-input border-0"
                                id="phone"
                                defaultValue={phone}
                                onChange={(e) => setPhone(e.target.value)}
                                placeholder="Enter Phone Number"
                              />
                            </div>
                          </li>
                          <div className="divider"></div>
                          <li className="list-group-item">
                            <div className="form-group">
                              <label
                                htmlFor="comments"
                                className="text-black ps-2 fs-6"
                              >
                                Provide a Brief Overview of Your Request
                              </label>
                              <textarea
                                type="text"
                                rows="2"
                                className="form-control w-100 bg-transparent form-input border-0"
                                id="comments"
                                defaultValue={comments}
                                onChange={(e) => setComments(e.target.value)}
                                placeholder="Enter Your Overview"
                              />
                            </div>
                          </li>
                          <li className="list-group-item">
                            <div className="form-check">
                              <input
                                className="form-check-input"
                                type="checkbox"
                                onChange={() => setCheck(!check)}
                                checked={check}
                                defaultValue=""
                                id="flexCheckChecked"
                              />
                              <label
                                className="form-check-label"
                                htmlFor="flexCheckChecked"
                              >
                                I consent to being contacted by MIP’s Leasing
                                team and to receive updates and promotional
                                material/offers
                              </label>
                            </div>
                          </li>
                        </ul>
                      </form>
                      {error && (
                        <div
                          className="toast align-items-center"
                          role="alert"
                          aria-live="assertive"
                          aria-atomic="true"
                        >
                          <div className="d-flex">
                            <div className="toast-body">{error}</div>
                            <button
                              className="btn-close me-2 m-auto"
                              data-bs-dismiss="toast"
                              aria-label="Close"
                              type="submit"
                            ></button>
                          </div>
                        </div>
                      )}
                      <button
                        className="border-0 btn btn-info text-white py-4 w-100"
                        disabled={!check}
                        type="button"
                        onClick={submitEcoSystem}
                      >
                        {loading ? (
                          <div
                            className="spinner-border spinner-border-sm"
                            role="status"
                          >
                            <span className="visually-hidden">Loading...</span>
                          </div>
                        ) : (
                          "Submit"
                        )}
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      );
    default:
      return (
        <div
          className="modal fade"
          id="formModal"
          tabIndex="-1"
          role="dialog"
          ref={formModalRef}
          aria-labelledby="singinModalRef"
          aria-hidden="true"
        >
          <div
            className="modal-dialog modal-lg modal-dialog-centered"
            role="document"
          >
            <div className="modal-content">
              <div className="modal-header p-0 bg-secondary">
                <h5
                  className="modal-title text-white px-5 py-4"
                  style={{ fontFamily: "Brandon Grotesque", fontWeight: 500 }}
                  id="exampleModalLongTitle"
                >
                  We look forward to working with you to design your custom lab
                  and office space please answer a few questions below to get us
                  started in the right direction.
                </h5>
              </div>
              <div className="modal-body p-0">
                <div className="container-fluid px-0">
                  <div className="row">
                    <div className="col-lg-12 d-flex flex-column align-items-center justify-content-center">
                      <form className="w-100" id="leasingForm">
                        <ul className="list-group list-group-flush">
                          <li className="list-group-item">
                            <div className="form-group">
                              <label
                                htmlFor="firstName"
                                className="text-black ps-2 fs-6"
                              >
                                First Name
                              </label>
                              <input
                                type="text"
                                className="form-control w-100 bg-transparent form-input border-0"
                                defaultValue={firstName}
                                onChange={(e) => setFirstName(e.target.value)}
                                id="firstName"
                                placeholder="Enter First Name"
                                required
                              />
                            </div>
                          </li>
                          <li className="list-group-item">
                            <div className="form-group">
                              <label
                                htmlFor="lastName"
                                className="text-black ps-2 fs-6"
                              >
                                Last Name
                              </label>
                              <input
                                type="text"
                                className="form-control w-100 bg-transparent form-input border-0"
                                defaultValue={lastName}
                                onChange={(e) => setLastName(e.target.value)}
                                id="lastName"
                                placeholder="Enter Last Name"
                                required
                              />
                            </div>
                          </li>
                          <div className="divider"></div>
                          <li className="list-group-item">
                            <div className="form-group">
                              <label
                                htmlFor="title"
                                className="text-black ps-2 fs-6"
                              >
                                Title
                              </label>
                              <input
                                type="text"
                                className="form-control w-100 bg-transparent form-input border-0"
                                defaultValue={title}
                                onChange={(e) => setTitle(e.target.value)}
                                id="title"
                                placeholder="Enter Title"
                              />
                            </div>
                          </li>
                          <div className="divider"></div>
                          <li className="list-group-item">
                            <div className="form-group">
                              <label
                                htmlFor="email"
                                className="text-black ps-2 fs-6"
                              >
                                Email Address
                              </label>
                              <input
                                type="email"
                                className="form-control w-100 bg-transparent form-input border-0"
                                id="email"
                                defaultValue={email}
                                onChange={(e) => setEmail(e.target.value)}
                                aria-describedby="emailHelp"
                                placeholder="Enter Email"
                                required
                              />
                            </div>
                          </li>
                          <li className="list-group-item">
                            <div className="form-group">
                              <label
                                htmlFor="phone"
                                className="text-black ps-2 fs-6"
                              >
                                Phone
                              </label>
                              <input
                                type="tel"
                                className="form-control w-100 bg-transparent form-input border-0"
                                id="phone"
                                defaultValue={phone}
                                onChange={(e) => setPhone(e.target.value)}
                                placeholder="Enter Phone Number"
                              />
                            </div>
                          </li>
                          <div className="divider"></div>
                          <li className="list-group-item">
                            <div className="form-group">
                              <label
                                htmlFor="desireSpace"
                                className="text-black ps-2 fs-6"
                              >
                                Estimated Total SQFT of Desired Space
                              </label>
                              <input
                                type="text"
                                className="form-control w-100 bg-transparent form-input border-0"
                                id="desireSpace"
                                defaultValue={desireSpace}
                                onChange={(e) => setDesireSpace(e.target.value)}
                                placeholder="Enter Your Desire Space in SQFT"
                                required
                              />
                            </div>
                          </li>
                          <li className="list-group-item">
                            <div className="form-group">
                              <label
                                htmlFor="comments"
                                className="text-black ps-2 fs-6"
                              >
                                Additional Comments
                              </label>
                              <textarea
                                type="text"
                                rows="2"
                                className="form-control w-100 bg-transparent form-input border-0"
                                id="comments"
                                defaultValue={comments}
                                onChange={(e) => setComments(e.target.value)}
                                placeholder="Enter Addition Comments"
                              />
                            </div>
                          </li>
                          <li className="list-group-item">
                            <div className="form-check">
                              <input
                                className="form-check-input"
                                type="checkbox"
                                onChange={() => setCheck(!check)}
                                checked={check}
                                defaultValue=""
                                id="flexCheckChecked"
                              />
                              <label
                                className="form-check-label"
                                htmlFor="flexCheckChecked"
                              >
                                I consent to being contacted by MIP’s Leasing
                                team and to receive updates and promotional
                                material/offers
                              </label>
                            </div>
                          </li>
                        </ul>
                      </form>
                      {error && (
                        <div
                          className="toast align-items-center"
                          role="alert"
                          aria-live="assertive"
                          aria-atomic="true"
                        >
                          <div className="d-flex">
                            <div className="toast-body">{error}</div>
                            <button
                              type="button"
                              className="btn-close me-2 m-auto"
                              data-bs-dismiss="toast"
                              aria-label="Close"
                            ></button>
                          </div>
                        </div>
                      )}
                      <button
                        type="button"
                        className="border-0 btn btn-info text-white py-4 w-100 formButton"
                        disabled={!check}
                        onClick={submitLeasing}
                      >
                        {loading ? (
                          <div
                            className="spinner-border spinner-border-sm"
                            role="status"
                          >
                            <span className="visually-hidden">Loading...</span>
                          </div>
                        ) : (
                          "Submit"
                        )}
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      );
  }
};
export default FormModal;
