import React, { useState, useEffect } from "react";
import useFetch from "../hooks/useFetch";
import EventCard from "./EventCard";
import MonthPicker from "./MonthPicker";

const Calendar = ({ content }) => {
  const date = new Date();
  let year = date.getFullYear();
  let month = date.getMonth() + 1;
  const range = {
    min: { year: year, month: month },
    max: { year: year + 1, month: month },
  };
  const months = [
    "Jan",
    "Feb",
    "Mar",
    "Apr",
    "May",
    "Jun",
    "Jul",
    "Aug",
    "Sep",
    "Oct",
    "Nov",
    "Dec",
  ];
  const [monthYear, setMonthYear] = useState({
    year: year,
    month: months[month - 1],
  });
  const [events, setEvents] = useState([]);

  const path = `${process.env.REACT_APP_ENDPOINT}/events?month=${monthYear.month}&year=${monthYear.year}`;
  const { data, error, loading } = useFetch(path);

  useEffect(() => {
    if (data) {
      setEvents(data);
    }
  }, [monthYear, loading, error, data]);

  return (
    <div className="d-flex mx-4 my-10 align-items-start flex-column">
      <div className="row px-md-10 w-100">
        <h2 className="fw-bolder display-4 lh-1 text-dark">
          Events Calendar{" "}
          <span className="fw-bolder fs-2 text-dark">
            {monthYear.month} {monthYear.year}
          </span>
        </h2>
      </div>
      {content.monthPicker && (
        <div className="row px-md-10">
          <MonthPicker
            range={range}
            monthYear={monthYear}
            months={months}
            setMonthYear={setMonthYear}
          />
        </div>
      )}
      <div className="row px-md-10 row-cols-1 row-cols-lg-4 w-100">
        {events.length > 0 &&
          events
            .sort(function (a, b) {
              return a.day - b.day;
            })
            .map((item, index) => <EventCard content={item} key={index} />)}
      </div>
    </div>
  );
};

export default Calendar;
