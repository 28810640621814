import React from "react";
import { Link } from "react-router-dom";

const SearchPage = ({ page }) => {
  return (
    <div className="p-4">
      <div
        className="mx-md-10 card border-0"
        style={{
          boxShadow:
            "rgba(14, 30, 37, 0.12) 0px 2px 4px 0px, rgba(14, 30, 37, 0.32) 0px 2px 16px 0px",
        }}
      >
        {page.banner ? (
          <div className="row g-0">
            <div className="col-md-4">
              <img
                src={`${process.env.REACT_APP_ENDPOINT}${page.banner?.media?.media[0]?.url}`}
                className="img-fluid rounded-start"
                alt={page.route}
                style={{ width: "100%", height: "35vh", objectFit: "cover" }}
              />
            </div>
            <div className="col-md-8">
              <div
                className="card-body"
                style={{ fontFamily: "Brandon Grotesque", fontWeight: 500 }}
              >
                <h5 className="card-title fw-bolder fs-4 my-2 mx-2">
                  {page.banner?.title}
                </h5>
                <p className="card-text fs-6 bg-transparent my-4 mx-2">
                  {page.banner?.description}
                </p>
              </div>
            </div>
          </div>
        ) : (
          <div
            className="card-body d-flex flex-column justify-content-center "
            style={{ fontFamily: "Brandon Grotesque", fontWeight: 500 }}
          >
            <h5 className="card-title fw-bolder fs-4 my-2 mx-2">
              {page.body[0].introduction}
            </h5>
            <p className="card-text fs-6 bg-transparent my-4 mx-2 text-truncate">
              {page.body[0].details}
            </p>
          </div>
        )}
        {page.route && (
          <Link
            to={`/pages/route/${page.route}`}
            className="stretched-link"
            target="_blank"
          >
            <i className="fa fa-phone" aria-hidden="true"></i>
          </Link>
        )}
      </div>
    </div>
  );
};

export default SearchPage;
