import React from "react";
import ReactMarkdown from "react-markdown";
import remarkBreaks from "remark-breaks";

const MapBlock = ({ content }) => {
  return (
    <div className="m-4">
      <div className="card bg-primary text-white mx-md-10 my-5">
        <img
          className="card-img"
          src={`${process.env.REACT_APP_ENDPOINT}${content.mapBackground.formats.large.url}`}
          alt="Card"
        />
        {content.title && (
          <div className="card-img-overlay w-50 ms-auto d-none d-xl-block overflow-auto">
            <div className={`card-text d-flex flex-column bg-${content.bgColor ? content.bgColor : "info"} m-5 px-md-10 py-5`}>
              <ReactMarkdown
                className="fs-1 text-white fw-bold"
                remarkPlugins={[remarkBreaks]}
              >
                {content.title}
              </ReactMarkdown>
              <ReactMarkdown
                className="fs-5 text-white fw-bold"
                remarkPlugins={[remarkBreaks]}
                linkTarget="_blank"
              >
                {content.contents}
              </ReactMarkdown>
            </div>
          </div>
 
        )}
      </div>
    </div>
  );
};

export default MapBlock;
