import React from 'react';
import './Gallery.scss';

const Gallery = ({ content }) => {
  const isUrl = React.useCallback(
    (imageKey) => {
      if (
        process?.env?.REACT_APP_ENDPOINT &&
        content?.imageGallery?.[imageKey]?.url
      ) {
        return (
          process.env.REACT_APP_ENDPOINT + content.imageGallery[imageKey].url
        );
      }
      return null;
    },
    [content.imageGallery]
  );

  const renderImage = React.useCallback(
    () =>
      Object.keys(content.imageGallery)
        .filter((k) => k.includes('image'))
        .map((key) =>
          !!isUrl(key) ? (
            <div className='col-xl-4 col-lg-6 cl-sm-12 py-2'>
              <div
                className='photo'
                style={{ backgroundImage: `url(${isUrl(key)})` }}
              />
            </div>
          ) : null
        ),
    [content.imageGallery, isUrl]
  );

  return <div className='row mx-md-10'>{renderImage()}</div>;
};

export default Gallery;
