import React from "react";
import { Helmet } from "react-helmet";

const Meta = ({
  title,
  quote,
  description,
  keywords,
  image,
  hashtag,
  currentUrl,
}) => {
  return (
    <Helmet>
      <title>{title}</title>
      <meta name="description" content={description} />
      <meta name="keyword" content={keywords} />
      <meta property="title" content={title} />
      <meta property="quote" content={quote} />
      <meta name="description" content={description} />
      <meta property="image" content={image} />
      <meta property="og:locale" content="en_US" />
      <meta property="og:type" content="website" />
      <meta property="og:title" content={title} />
      <meta property="og:quote" content={quote} />
      <meta property="og:hashtag" content={hashtag} />
      <meta property="og:url" content={currentUrl} />
      <meta property="og:site_name" content="" />
      <meta property="og:description" content={description} />
      <meta property="og:image" content={image} />
      <meta content="image/*" property="og:image:type" />
    </Helmet>
  );
};

Meta.defaultProps = {
  title: "MIP",
  description: "Grow with McMaster",
  quote: "",
  image: "https://ibb.co/CWDsMMJ",
  currentUrl: `${process.env.REACT_APP_ENDPOINT}`,
  keywords: "MIP, McMaster Innovation Park, Industry, Development",
  hashtag: "MIP",
};

export default Meta;
