import React from 'react';
import ImageWithFallback from './ImageWithFallback';

const Carousel = ({ contentModule, name, height }) => {
  let remainCopy;

  if (contentModule.length > 1) {
    remainCopy = contentModule.slice(1);
  }
  
  const ImgStyle = React.useMemo(
    () => ({
      objectFit: 'cover',
      objectPosition: 'center center',
      height: `${height ? `${height}px` : 'auto'}`,
      boxShadow:
        'rgba(50, 50, 93, 0.25) 0px 2px 5px -1px, rgba(0, 0, 0, 0.3) 0px 1px 3px -1px',
    }),
    [height]
  );

  return (
    <div
      id={name}
      className='carousel slide'
      data-bs-ride='carousel'
      style={{ height: `${height ? `${height}px` : 'auto'}`, minWidth: '100%' }}
    >
      <div
        className='carousel-inner'
        style={{ height: `${height ? `${height}px` : 'auto'}` }}
      >
        <div
          className='carousel-item active'
          style={{ height: `${height ? `${height}px` : 'auto'}` }}
        >
          <ImageWithFallback
            src={`${process.env.REACT_APP_ENDPOINT}${contentModule?.[0]?.formats?.large?.url}`}
            className='d-block w-100'
            style={ImgStyle}
            alt='test'
            fallbackSrc={`${process.env.REACT_APP_ENDPOINT}${contentModule[0].url}`}
          />
        </div>
        {remainCopy &&
          remainCopy.map((item) => (
            <div className='carousel-item' key={item.id} style={{ height: `${height ? `${height}px` : 'auto'}` }}>
              <ImageWithFallback
                src={`${process.env.REACT_APP_ENDPOINT}${item?.formats?.large?.url}`}
                className='d-block w-100'
                style={ImgStyle}
                alt={item.alternativeText}
                fallbackSrc={`${process.env.REACT_APP_ENDPOINT}${item.url}`}
              />
            </div>
          ))}
      </div>
      <button
        className='carousel-control-prev'
        type='button'
        data-bs-target={`#${name}`}
        data-bs-slide='prev'
      >
        <span className='carousel-control-prev-icon' aria-hidden='true'></span>
        <span className='visually-hidden'>Previous</span>
      </button>
      <button
        className='carousel-control-next'
        type='button'
        data-bs-target={`#${name}`}
        data-bs-slide='next'
      >
        <span className='carousel-control-next-icon' aria-hidden='true'></span>
        <span className='visually-hidden'>Next</span>
      </button>
    </div>
  );
};

export default Carousel;
