import React from "react";
import PropTypes from "prop-types";

const EmbedVideo = ({ src }) => (
  <div
    className="video-responsive"
    style={{
      boxShadow:
        "rgba(0, 0, 0, 0.19) 0px 10px 20px, rgba(0, 0, 0, 0.23) 0px 6px 6px",
    }}
  >
    <iframe
      src={src}
      frameBorder="0"
      allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
      allowFullScreen
      title="Embedded video"
    />
  </div>
);

EmbedVideo.propTypes = {
  src: PropTypes.string.isRequired,
};

export default EmbedVideo;
