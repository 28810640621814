import React, { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import { RiMapPinLine } from "react-icons/ri";
import { AiOutlineClockCircle, AiOutlineCalendar } from "react-icons/ai";
import { FiDollarSign } from "react-icons/fi";
import useFetch from "../hooks/useFetch";

function EventPage() {
  const { id } = useParams();
  const path = `${process.env.REACT_APP_ENDPOINT}/events/${id}`;
  const { data, loading, error } = useFetch(path);

  const [event, setEvent] = useState();

  useEffect(() => {
    if (data) {
      setEvent(data);
    }
  }, [loading, error, data]);

  return (
    <div className="container-fluid px-md-10 pb-10 pt-5 h-100">
      {event && (
        <div className="row g-0">
          <div className="col-md-7 d-flex flex-column align-items-start justify-content-start px-4">
            <h6 className="text-info fs-4 fw-bold">{event.eventCategory}</h6>
            <h2
              className="text-dark display-4 fw-bolder"
              style={{ fontFamily: "Brandon Grotesque" }}
            >
              {event.eventName}
            </h2>
            <p className="fs-5 fw-bold text-light">{event.eventDescription}</p>
            <div className="mt-5 mb-3 divider" style={{ height: "0.1rem" }}>
              {" "}
            </div>
            <div className="row w-100 text-light mb-3">
              <div className="col d-flex align-items-center">
                <AiOutlineCalendar size="2rem" className="me-3" />
                {event.month} {event.day}, {event.year}
              </div>
              <div className="col d-flex align-items-center">
                <AiOutlineClockCircle size="2rem" className="me-3" />
                {event.startTime} - {event.endTime}
              </div>
            </div>
            <div className="row w-100 text-light">
              <div className="col d-flex align-items-center">
                <RiMapPinLine size="2rem" className="me-3" />
                {event.location}
              </div>
              <div className="col d-flex align-items-center">
                <FiDollarSign size="2rem" className="me-3" />
                {event.price}
              </div>
            </div>
            <div className="mt-4 mb-3 divider" style={{ height: "0.1rem" }}>
              {" "}
            </div>
            <div className="row w-100">
              <div className="col-md-6">
                <a
                  className="btn bg-secondary rounded text-white"
                  rel="noreferrer noopener"
                  target="_blank"
                  href={event.eventLink}
                >
                  Go To Event Website
                </a>
              </div>
            </div>
          </div>

          <div className="col-md-5 d-flex flex-column align-items-start justify-content-start pe-4 ps-10">
            <h4
              className="fs-1 text-dark fw-bolder pt-2"
              style={{ fontFamily: "Brandon Grotesque" }}
            >
              Event Organizer
            </h4>
            <p className="text-light fs-6 fw-bolder mt-2">
              {event.eventOrganizerName}
            </p>
            <div className="row">
              <p className="fs-6 fw-bold text-light">
                phone: {event.eventOrganizerPhone}
              </p>
            </div>
            <div className="row">
              <p className="fs-6 fw-bold text-light">
                email: {event.eventOrganizerEmail}
              </p>
            </div>

            {data?.eventLogo && (
              <img
                alt={data?.eventLogo.caption}
                src={`${process.env.REACT_APP_ENDPOINT}${data?.eventLogo.url}`}
                className="w-75 rounded"
              ></img>
            )}
          </div>
        </div>
      )}
    </div>
  );
}

export default EventPage;
