import React, { useState } from 'react';
import ReactMarkdown from 'react-markdown';
import remarkBreaks from 'remark-breaks';
import FormModal from './FormModal';
import Carousel from './Carousel';
import ImageWithFallback from './ImageWithFallback';

const Hero = ({ contentModule }) => {
  const [formModal, setFormModal] = useState(false);
  const [height, setHeight] = useState(0);
  const ref = React.useRef(null);

  React.useEffect(() => {
    setHeight(ref?.current?.clientHeight - 48);
  }, []);

  function ButtonForm({ btn }) {
    if (btn.openForm) {
      return (
        <React.Fragment>
          <button
            type='button'
            onClick={() => setFormModal(true)}
            style={{
              boxShadow:
                'rgba(50, 50, 93, 0.25) 0px 2px 5px -1px, rgba(0, 0, 0, 0.3) 0px 1px 3px -1px',
            }}
            className={`btn btn-lg rounded-3 bg-${
              btn.color ? btn.color : 'info'
            } fw-bolder text-white fs-3 mt-3`}
          >
            {btn.name.toUpperCase()}
          </button>
          <FormModal
            show={formModal}
            backdrop={true}
            onHide={() => setFormModal(false)}
            type={btn.formType}
          />
        </React.Fragment>
      );
    } else {
      return (
        <a
          type='button'
          href={btn.Link}
          target='_blank'
          rel='noreferrer noopener'
          style={{
            boxShadow:
              'rgba(50, 50, 93, 0.25) 0px 2px 5px -1px, rgba(0, 0, 0, 0.3) 0px 1px 3px -1px',
          }}
          className={`btn btn-lg rounded-3 bg-${
            btn.color ? btn.color : 'info'
          } fw-bolder text-white fs-4 mt-3`}
        >
          {btn.name.toUpperCase()}
        </a>
      );
    }
  }

  return (
    <div className='row d-flex flex-row align-items-center mb-6' ref={ref}>
      <div
        className={`col-xl-6 col-lg-12 bg-${contentModule.theme} text-white p-md-10 p-4 d-flex flex-column align-items-start justify-contents-center`}
      >
        <div className='overflow-visible m-xl-4 m-lg-0'>
          <ReactMarkdown
            className='display-5  fw-bolder lh-1'
            remarkPlugins={[remarkBreaks]}
          >
            {contentModule.title}
          </ReactMarkdown>
          <ReactMarkdown
            className='fs-2 fw-bold lh-1'
            remarkPlugins={[remarkBreaks]}
            linkTarget='_blank'
          >
            {contentModule.description}
          </ReactMarkdown>
          {contentModule.button &&
            contentModule.button.map((btn) => (
              <ButtonForm btn={btn} key={btn.id} />
            ))}
        </div>
      </div>
      <div
        className='col-xl-6 col-lg-12 p-0 d-flex hero-banner'
        style={{ marginLeft: '-40px' }}
      >
        {contentModule.media.type === 'carousel' ||
        contentModule.media.media.length > 1 ? (
          <Carousel
            contentModule={contentModule.media.media}
            name='hero'
            height={height}
          />
        ) : (
          <>
            <ImageWithFallback
              src={{
                backgroundImage: `url(${process?.env?.REACT_APP_ENDPOINT}${contentModule?.media?.media[0]?.formats?.large?.url}`,
              }}
              style={{
                minWidth: '100%',
                height: `${height}px`,
                maxHeight: `${height}px`,
                objectFit: 'cover',
                objectPosition: 'center center',
              }}
              alt='single demo'
              fallbackSrc={`${process.env.REACT_APP_ENDPOINT}${contentModule.media.media[0].url}`}
            />
            {contentModule.media.media[0].caption && (
              <p className='text-dark fw-bolder fs-6 mt-2' align='right'>
                {contentModule.media.media[0].caption}
              </p>
            )}
          </>
        )}
      </div>
    </div>
  );
};

export default Hero;
