import React from "react";

const Table = ({ content }) => {
  return (
    <div className="px-md-10 mx-4 collapse mb-4" id={content.targetName}>
      {/* {content.header && (
        <div className="fs-5 text-primary fw-bold">{content.header}</div>
      )} */}
      <div className="table-responsive">
        <table className="table table-hover">
          {content.footer && <caption>{content.footer}</caption>}
          <thead>
            <tr>
              <th scope="col">#</th>
              <th scope="col"></th>
              <th scope="col"></th>
            </tr>
          </thead>
          <tbody>
            {content.buildingStatsRow?.map((row, index) => (
              <tr key={row.id}>
                <th scope="row">{index + 1}</th>
                <td className="text-dark">{row.attribute}</td>
                <td className="text-dark">{row.value}</td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    </div>
  );
};

export default Table;
