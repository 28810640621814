import React, { useState, useEffect } from "react";
import useFetch from "../hooks/useFetch";
import SearchPage from "./SearchPage";
import { AiOutlineSearch } from "react-icons/ai";

const Search = ({ classes }) => {
  const [keyword, setKeyword] = useState("");
  const path = `${process.env.REACT_APP_ENDPOINT}/pages`;
  const { loading, error, data } = useFetch(path);
  const [pagesContainsKeywrod, setPagesContainsKeywrod] = useState([]);

  useEffect(() => {
    if (error || loading || keyword === "") {
      setPagesContainsKeywrod([]);
    } else if (data && data.length > 0) {
      setPagesContainsKeywrod(
        data.filter((page) => JSON.stringify(page).includes(keyword))
      );
    }
  }, [keyword, data, error, loading]);

  return (
    <>
      <div className="mx-4">
        <div className={`input-group ${classes} flex-nowrap`}>
          <span
            className="input-group-text bg-white border-end-0"
            id="addon-wrapping"
          >
            <AiOutlineSearch className="text-primary" />
          </span>
          <input
            type="text"
            className="form-control border-start-0 searchInput"
            placeholder="Search..."
            onChange={(e) => setKeyword(e.target.value)}
            aria-label="Website search"
            aria-describedby="addon-wrapping"
          />
        </div>
      </div>
      {pagesContainsKeywrod &&
        pagesContainsKeywrod.map((page, index) => (
          <SearchPage key={index} page={page} />
        ))}
    </>
  );
};

export default Search;
