import React from "react";
import { AiFillRightCircle } from "react-icons/ai";
import { Link } from "react-router-dom";
import {
  EmailIcon,
  FacebookIcon,
  TwitterShareButton,
  EmailShareButton,
  TwitterIcon,
  FacebookShareButton,
} from "react-share";

function NewsCard({ content }) {
  return (
    <>
      <div className="py-3 gx-4">
        <div
          className="card rounded w-100 d-flex border-0"
          style={{
            boxShadow: "10px 10px 24px 2px rgb(50 50 50 / 39%)",
          }}
        >
          <div className="row">
            <div className="col-lg-5">
              {content.cover && (
                <Link to={`/news/${content.id}`} target="_blank" role="button">
                  <img
                    src={`${process.env.REACT_APP_ENDPOINT}${content.cover.url}`}
                    className="p-4"
                    style={{
                      objectFit: "cover",
                      objectPosition: "20% 30%",
                      width: "100%",
                      height: "300px",
                    }}
                    alt={content.cover.caption}
                  />
                </Link>
              )}
            </div>
            <div className="col-lg-7">
              <div className="card-body px-4 pt-4 pb-3">
                <div
                  className="card-text d-flex flex-column"
                  style={{ fontFamily: "Brandon Grotesque", fontWeight: 400 }}
                >
                  {content.date && (
                    <p className="fs-7 fw-bolder" style={{ color: "#b0b0b0" }}>
                      {content.date}
                    </p>
                  )}
                  {content.title && (
                    <Link
                      to={`/news/${content.id}`}
                      target="_blank"
                      role="button"
                      className="text-decoration-none"
                    >
                      <p className="text-dark fs-5 fw-bolder">
                        {content.title}
                      </p>
                    </Link>
                  )}
                  {content.content && (
                    <Link
                      to={`/news/${content.id}`}
                      target="_blank"
                      role="button"
                      className="text-decoration-none"
                    >
                      <p style={{ color: "#b0b0b0" }}>
                        {content.content.trim().split(/\r?\n/)[0]}
                      </p>
                    </Link>
                  )}
                  <div className="decoration d-flex">
                    <Link
                      to={`/news/${content.id}`}
                      target="_blank"
                      className="text-decoration-none"
                    >
                      <button className="btn border-end border-2 ps-0 d-flex align-items-center fw-bolder">
                        Read More{" "}
                        <AiFillRightCircle
                          size="1.2rem"
                          color="#850044"
                          className="ms-2"
                        />
                      </button>
                    </Link>
                    <div className="d-flex align-items-center ms-3">
                      <FacebookShareButton
                        windowWidth={750}
                        windowHeight={600}
                        url={`${process.env.REACT_APP_ENDPOINT}/news/${content.id}`}
                        className="mx-1"
                      >
                        <FacebookIcon size={32} round />
                      </FacebookShareButton>
                      <TwitterShareButton
                        title={content.title}
                        windowWidth={750}
                        windowHeight={600}
                        url={`${process.env.REACT_APP_ENDPOINT}/news/${content.id}`}
                        className="mx-1"
                      >
                        <TwitterIcon size={32} round />
                      </TwitterShareButton>
                      {/* <LinkedinShareButton
                        title={content.title}
                        summary={content.content.trim().split(/\r?\n/)[0]}
                        windowWidth={750}
                        windowHeight={600}
                        source={`${process.env.REACT_APP_ENDPOINT}/news/${content.id}`}
                        url={`${process.env.REACT_APP_ENDPOINT}/news/${content.id}`}
                        className="mx-1"
                      >
                        <LinkedinIcon size={"2rem"} round />
                      </LinkedinShareButton> */}
                      <EmailShareButton
                        subject={content.title}
                        body={content.content}
                        className="mx-1"
                      >
                        <EmailIcon size={"2rem"} round />
                      </EmailShareButton>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default NewsCard;
