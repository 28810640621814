import React from "react";
import ImageCardSet from "./ImageCardSet";
import TextDividerWButton from "./TextDividerWButton";
import ImageCardCaptionSet from "./ImageCardCaptionSet";
import SubscribeBlock from "./SubscribeBlock";
import MapBlock from "./MapBlock";
import SwipeableView from "./SwipeableView";
import IconTextButton from "./IconTextButton";
import Table from "./Table";
import Accordion from "./Accordion";
import Calendar from "./Calendar";
import News from "./News";
import Gallery from './Gallery';
import TextTemplateSet from './TextTemplateSet';

const FlexibleContentModule = ({ contentModule }) => {
  switch (contentModule.__component) {
    case "page.horizontal-text-block":
      return <IconTextButton content={contentModule} />;
    case "page.display-cards":
      return <ImageCardCaptionSet content={contentModule} />;
    case "page.transition-block":
      return <TextDividerWButton content={contentModule} />;
    case "page.event-cards":
      return <ImageCardSet content={contentModule} />;
    case "page.map-block":
      return <MapBlock content={contentModule} />;
    case "page.subscribe-block":
      return <SubscribeBlock content={contentModule} />;
    case "page.testimonial-block":
      return <SwipeableView content={contentModule} />;
    case "page.building-stats":
      return <Table content={contentModule} />;
    case "page.accordion":
      return <Accordion content={contentModule} />;
    case "page.calendar":
      return <Calendar content={contentModule} />;
    case "page.news":
      return <News content={contentModule} />;
    case "page.image-gallery":
      return <Gallery content={contentModule} />;
    case "page.text-template":
      return <TextTemplateSet content={contentModule} />;
    default:
      return (
        <div className="alert alert-danger" role="alert">
          components {contentModule.__component} not found, please contact admin
        </div>
      );
  }
};

export default FlexibleContentModule;
