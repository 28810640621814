import React from "react";
import ReactMarkdown from "react-markdown";
import remarkBreaks from "remark-breaks";
import ImageWithFallback from "./ImageWithFallback";

const TextTemplateSet = ({ content }) => {
  return (
    <div className="row row-cols-xl-3 row-cols-lg-2 row-cols-md-1 gy-3 mx-md-10 mx-4 mb-5">
      {content.TextParagraphs &&
        content.TextParagraphs.map((item, index) => (
          <div key={index} className="d-flex flex-column">
            <div className="header d-flex align-items-center mb-3 justify-content-center">
              {item.icon && (
                <ImageWithFallback
                  src={`${process.env.REACT_APP_ENDPOINT}${
                    item.icon.formats?.thumbnail &&
                    item.icon.formats.thumbnail.url
                  }`}
                  className="rounded-circle mb-1 me-2"
                  style={{ width: "50px", height: "50px" }}
                  alt="article icon"
                  fallbackSrc={`${process.env.REACT_APP_ENDPOINT}${item.icon.url}`}
                />
              )}
              <h5 className="fs-1 fw-bolder text-primary">{item.header}</h5>
            </div>
            <ReactMarkdown
              className={`text-dark text-left fs-4 px-4 fw-bold mb-3`}
              remarkPlugins={[remarkBreaks]}
              linkTarget="_blank"
            >
              {item.contents}
            </ReactMarkdown>
          </div>
        ))}
    </div>
  );
};

export default TextTemplateSet;