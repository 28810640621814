import React, { useEffect, useState } from "react";
import useFetch from "../hooks/useFetch";
import ReactMarkdown from "react-markdown";
import remarkBreaks from "remark-breaks";
import { FaFacebookF, FaInstagram, FaTwitter, FaYoutube } from "react-icons/fa";

const Footer = () => {
  const path = `${process.env.REACT_APP_ENDPOINT}/footer`;

  const { data, error } = useFetch(path);

  const [footerContent, setFooterContent] = useState();

  useEffect(() => {
    const localData = localStorage.getItem("footerContent");
    if (data !== null) {
      localStorage.setItem("footerContent", JSON.stringify(data));
      setFooterContent(data);
    } else {
      setFooterContent(JSON.parse(localData));
    }
  }, [data]);

  return (
    <>
      <div className="container-fluid bg-primary py-5 px-md-10">
        <div
          className="row d-flex align-items-center p-md-5 text-xxl-start"
          style={{ fontFamily: "Brandon Grotesque", fontWeight: 500 }}
        >
          <div className="col-xxl-3 text-center">
            <ReactMarkdown
              className="fs-6 text-white"
              remarkPlugins={[remarkBreaks]}
            >
              {footerContent?.contacts}
            </ReactMarkdown>
            <ReactMarkdown
              className="fs-6 text-white"
              remarkPlugins={[remarkBreaks]}
            >
              {footerContent?.address}
            </ReactMarkdown>
          </div>
          <div className="col-xxl-3 py-4 align-self-stretch d-flex flex-column align-items-center justify-content-between">
            {footerContent?.links &&
              footerContent?.links.map((link, index) => (
                <a href={link.value} key={index} className="text-white fs-6">
                  {link.attribute}
                </a>
              ))}
          </div>
          <div className="col-xxl-4 offset-xxl-2">
            <div className="d-flex justify-content-around">
              {footerContent?.socialMedias &&
                footerContent.socialMedias.map((item) => {
                  switch (item.name) {
                    case "facebook":
                      return (
                        <button
                          key={item.id}
                          className="btn bg-white p-3 rounded-circle"
                          type="button"
                          href={item.link}
                          target="_blank"
                        >
                          <FaFacebookF size="2.5em" className="text-primary" />
                        </button>
                      );
                    case "instagram":
                      return (
                        <button
                          key={item.id}
                          className="btn bg-white p-3 rounded-circle"
                          type="button"
                          href={item.link}
                          target="_blank"
                        >
                          <FaInstagram size="2.5em" className="text-primary" />
                        </button>
                      );
                    case "twitter":
                      return (
                        <button
                          key={item.id}
                          className="btn bg-white p-3 rounded-circle"
                          type="button"
                          href={item.link}
                          target="_blank"
                        >
                          <FaTwitter size="2.5em" className="text-primary" />
                        </button>
                      );
                    case "youtube":
                      return (
                        <button
                          key={item.id}
                          className="btn bg-white p-3 rounded-circle"
                          type="button"
                          href={item.link}
                          target="_blank"
                        >
                          <FaYoutube size="2.5em" className="text-primary" />
                        </button>
                      );
                    default:
                      return <p>icon not imported, please contact admin</p>;
                  }
                })}
            </div>
          </div>
        </div>
      </div>
      {error && (
        <div className="alert alert-danger" role="alert">
          footer isn't loading correctly, please contact admin
        </div>
      )}
    </>
  );
};

export default Footer;
