export const USER_LOGIN_REQUEST = "USER_LOGIN_REQUEST";
export const USER_LOGIN_SUCCESS = "USER_LOGIN_SUCCESS";
export const USER_SIGNIN_SUCCESS = "USER_SIGNIN_SUCCESS";
export const USER_LOGIN_FAIL = "USER_LOGIN_FAIL";
export const USER_LOGOUT = "USER_LOGOUT";

export const USER_REGISTER_REQUEST = "USER_REGISTER_REQUEST";
export const USER_REGISTER_SUCCESS = "USER_REGISTER_SUCCESS";
export const USER_REGISTER_FAIL = "USER_REGISTER_FAIL";

export const USER_UPDATE_REQUEST = "USER_UPDATE_REQUEST";
export const USER_UPDATE_SUCCESS = "USER_UPDATE_SUCCESS";
export const USER_UPDATE_FAIL = "USER_UPDATE_FAIL";

export const USER_UPDATE_PASSWORD_REQUEST = "USER_UPDATE_PASSWORD_REQUEST";
export const USER_UPDATE_PASSWORD_SUCCESS = "USER_UPDATE_PASSWORD_SUCCESS";
export const USER_UPDATE_PASSWORD_FAIL = "USER_UPDATE_PASSWORD_FAIL";

export const USER_VERIFY_EMAIL_REQUEST = "USER_VERIFY_EMAIL_REQUEST";
export const USER_VERIFY_EMAIL_SUCCESS = "USER_VERIFY_EMAIL_SUCCESS";
export const USER_VERIFY_EMAIL_FAIL = "USER_VERIFY_EMAIL_FAIL";

export const USER_RESET_PASSWORD_REQUEST = "USER_RESET_PASSWORD_REQUEST";
export const USER_RESET_PASSWORD_SUCCESS = "USER_RESET_PASSWORD_SUCCESS";
export const USER_RESET_PASSWORD_FAIL = "USER_RESET_PASSWORD_FAIL";

export const USER_FORGOT_PASSWORD_REQUEST = "USER_FORGOT_PASSWORD_REQUEST";
export const USER_FORGOT_PASSWORD_SUCCESS = "USER_FORGOT_PASSWORD_SUCCESS";
export const USER_FORGOT_PASSWORD_FAIL = "USER_FORGOT_PASSWORD_FAIL";

export const EMAIL_ECO_SYSTEM_REQUEST = "EMAIL_ECO_SYSTEM_REQUEST";
export const EMAIL_ECO_SYSTEM_SUCCESS = "EMAIL_ECO_SYSTEM_SUCCESS";
export const EMAIL_ECO_SYSTEM_FAIL = "EMAIL_ECO_SYSTEM_FAIL";

export const EMAIL_LEASING_REQUEST = "EMAIL_LEASING_REQUEST";
export const EMAIL_LEASING_SUCCESS = "EMAIL_LEASING_SUCCESS";
export const EMAIL_LEASING_FAIL = "EMAIL_LEASING_FAIL";