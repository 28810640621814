import React from 'react';
import PropTypes from 'prop-types';
import ReactMarkdown from 'react-markdown';
import remarkBreaks from 'remark-breaks';
import ImageWithFallback from './ImageWithFallback';

const ImageCard = ({
  text,
  color,
  imgLink,
  altText,
  link,
  description,
  type,
  imgHeight,
  imgWidth,
  titlePaddingY,
}) => {
  return (
    <div className='px-4'>
      <div
        className='card border-0 d-flex flex-column align-items-center'
        style={{
          backgroundColor: 'transparent',
        }}
      >
        {imgLink && (
          <ImageWithFallback
            src={`${process.env.REACT_APP_ENDPOINT}${
              imgLink.formats?.large && imgLink.formats.large.url
            }`}
            className='card-img-top'
            style={{
              maxHeight: `${imgHeight}`,
              width: `${imgWidth}`,
              height: `${imgHeight}`,
              objectFit: `${type}`,
            }}
            alt={altText}
            fallbackSrc={`${process.env.REACT_APP_ENDPOINT}${imgLink.url}`}
          />
        )}
        {text && (
          <div className={color + ' w-100'}>
            <ReactMarkdown
              className='card-title fw-bolder fs-4 text-center text-white m-0 px-4 py-3'
              remarkPlugins={[remarkBreaks]}
              components={{ p: 'span' }}
            >
              {text.toUpperCase()}
            </ReactMarkdown>
          </div>
        )}
        {link && (
          <a
            href={link}
            className='stretched-link'
            target='_blank'
            rel='noreferrer noopener'
          >
            <i className='fa fa-phone' aria-hidden='true'></i>
          </a>
        )}
      </div>
      {description && (
        <div
          className='pt-4 px-2'
          style={{ fontFamily: 'Brandon Grotesque', fontWeight: 500 }}
        >
          <ReactMarkdown
            className='fs-3 bg-transparent text-dark m-0'
            remarkPlugins={[remarkBreaks]}
            children={description}
            linkTarget='_blank'
          ></ReactMarkdown>
        </div>
      )}
    </div>
  );
};

ImageCard.propTypes = {
  text: PropTypes.string,
  color: PropTypes.string,
  imgLink: PropTypes.object,
  altText: PropTypes.string,
  description: PropTypes.string,
  type: PropTypes.string,
  imgHeight: PropTypes.string,
  imgWidth: PropTypes.string,
  titlePaddingY: PropTypes.string,
  link: PropTypes.string,
};

export default ImageCard;
