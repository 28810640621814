import React from "react";
import PropTypes from "prop-types";
import ReactMarkdown from "react-markdown";
import remarkBreaks from "remark-breaks";
import { FaQuoteLeft } from "react-icons/fa";

const AvatarCardComment = ({
  title,
  avatarLink,
  logoLink,
  link,
  altText,
  comment,
}) => {
  return (
    <div className="py-5 gx-5">
      <div className={"card border-0"}>
        <img
          src={avatarLink}
          className="card-img-top mx-auto mt-4"
          alt={altText}
          style={{
            width: "120px",
            height: "120px",
            borderRadius: "50%",
            objectFit: "cover",
          }}
        />
        <div className="d-flex justify-content-center align-items-center">
          <ReactMarkdown
            className="card-title fw-bolder fs-6 text-center text-dark m-0 px-4 py-3"
            remarkPlugins={[remarkBreaks]}
            components={{ p: "span" }}
          >
            {title}
          </ReactMarkdown>
        </div>
        <div className="d-flex px-5 pb-4">
          {comment && (
            <>
              <span>
                <FaQuoteLeft className="text-secondary" />
              </span>
              <ReactMarkdown
                className={`card-text fs-6 fw-bold lh-sm text-dark ps-2 py-2`}
                remarkPlugins={[remarkBreaks]}
                components={{ p: "span" }}
                linkTarget="_blank"
              >
                {comment}
              </ReactMarkdown>
            </>
          )}
        </div>
        {link && (
          <a
            href={link}
            className="stretched-link"
            target="_blank"
            rel="noreferrer noopener"
          >
            <i className="fa fa-phone" aria-hidden="true"></i>
          </a>
        )}
      </div>
    </div>
  );
};

AvatarCardComment.propTypes = {
  title: PropTypes.string,
  avatarLink: PropTypes.string,
  logoLink: PropTypes.string,
  altText: PropTypes.string,
  comment: PropTypes.string,
};

export default AvatarCardComment;
