import React from "react";
import ReactMarkdown from "react-markdown";
import remarkBreaks from "remark-breaks";

const SubscribeBlock = ({ content }) => {
  return (
    <div className="p-4">
      <div
        className="m-md-10"
        style={{
          boxShadow:
            "rgba(14, 30, 37, 0.12) 0px 2px 4px 0px, rgba(14, 30, 37, 0.32) 0px 2px 16px 0px",
        }}
      >
        <div
          className={`bg-${
            content.bgColor ? content.bgColor : "primary"
          } text-center py-5`}
        >
          <ReactMarkdown
            className="fs-2 fw-bolder text-white pb-3"
            children={content.introduction}
            remarkPlugins={[remarkBreaks]}
          />
          <div className="input-group input-group-lg px-5 d-none d-md-flex">
            <input
              type="text"
              className="form-control py-3 fs-3"
              placeholder={content.placeHolder}
              id="subscribeblock"
            />
            {content.button && (
              <span
                className={`input-group-text btn bg-${
                  content.button.color ? content.button.color : "secondary"
                } px-md-10 d-flex w-25 justify-content-center text-white fw-bolder fs-3`}
                id="basic-addon2"
              >
                {content.button.name}
              </span>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default SubscribeBlock;
